var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { cloneElement, useState } from "react";
import "./Icon.less";
var Icon = function (props) {
    var _a = useState(props.size || "16"), size = _a[0], setSize = _a[1];
    var fill = props.fill || "#252525";
    var fillHover = props.fillHover || fill;
    var _b = useState(false), isHovered = _b[0], setIsHovered = _b[1];
    var style = useState(Object.assign(__assign({}, props.style) || {}, { width: "".concat(size, "px"), height: "".concat(size, "px") }))[0];
    var handleMouseEnter = function () {
        setIsHovered(true);
    };
    var handleMouseLeave = function () {
        setIsHovered(false);
    };
    var handleMouseDown = function (event) {
        if (props.handleOnMouseDown) {
            props.handleOnMouseDown(event, props);
        }
    };
    var handleMouseUp = function (event) {
        if (props.handleOnMouseUp) {
            //console.log(props, 88);
            props.handleOnMouseUp(event, props);
        }
    };
    return (_jsx("div", { className: props.className ? props.className : "icon-prb", onClick: props.onClick, style: style, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onMouseDown: handleMouseDown, onMouseUp: handleMouseUp, children: props.children && cloneElement(props.children, {
            style: style,
            size: size,
            fill: isHovered ? fillHover : fill,
        }) }));
};
export default Icon;
