var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useContext, useRef } from 'react';
import { FormContext } from "@/app/form";
import { FloatType, IntegerType } from "@/app/form/data-types";
var InputMask = function (props) {
    //useEffect(() => {}, []);
    var inputRef = useRef(null);
    var context = useContext(FormContext);
    var name = useState(props.name || "")[0];
    var _a = useState(context.getValue(name) || ""), value = _a[0], setValue = _a[1];
    var _b = useState(false), isFocused = _b[0], setIsFocused = _b[1];
    var _c = useState(props.type || 'string'), inputType = _c[0], setInputType = _c[1];
    var _d = useState(props.valueFormat || 'string'), valueFormat = _d[0], setValueFormat = _d[1];
    var htmlFor = "".concat(inputType, "-").concat(Math.random());
    var applyMask = function (rawValue) {
        if (!rawValue.length) {
            return "";
        }
        //var pattern = "\D";
        //re = new RegExp(pattern, "g");
        var value = rawValue.replace(/\D/g, ""); //.match(/.{1,4}/g)?.join(" ").substr(0, 19) || "";
        value = '+' + value;
        var newValue = "";
        var cursorValue = 0;
        for (var cursor = 0; cursor <= props.mask.length; cursor++) {
            if (!value[cursorValue]) {
                break;
            }
            if (props.mask[cursor] != 9) {
                newValue += props.mask[cursor];
                if (props.mask[cursor] == value[cursorValue]) {
                    cursorValue++;
                }
            }
            else {
                if (value[cursorValue]) {
                    newValue += value[cursorValue];
                    cursorValue++;
                }
                else {
                    break;
                }
            }
        }
        return newValue.substr(0, props.mask.length);
    };
    var label = (typeof props.label != "undefined") ?
        _jsx("label", { htmlFor: htmlFor, className: "form_item__label ".concat(isFocused ? "focused" : ""), children: props.label }) : "";
    var disabled = false;
    if (typeof props.disabled === "function") {
        disabled = props.disabled(context);
    }
    else if (typeof props.disabled != "undefined") {
        disabled = props.disabled;
    }
    var onChangeInput = function (e) {
        var newValue = e.target.value;
        var maskedValue = applyMask(newValue);
        setValue(maskedValue);
        context.setValue(name, setTypeFormat(newValue));
    };
    var setTypeFormat = function (rawValue) {
        var value = rawValue;
        switch (valueFormat) {
            case "float":
                value = FloatType(rawValue);
                break;
            case "integer":
                value = IntegerType(rawValue);
                break;
        }
        return value || "";
    };
    // const onCLickByIcon = (e) => {
    //   setInputType('text');
    // }
    // let value = context.getValue(name);
    var input = _jsx("input", { ref: inputRef, type: inputType, placeholder: props.placeholder, id: htmlFor, name: name, value: context.getValue(name) || "", disabled: disabled, className: props.class || 'form_item__input', autoComplete: "off", onFocus: function () {
            setIsFocused(true);
        }, onBlur: function () {
            setIsFocused(false);
        }, onChange: onChangeInput, onInput: function (e) {
            if (typeof props.onInput !== "function") {
                return;
            }
            props.onInput(e.target.value, __assign({}, props));
        } });
    if (props.type == "hidden") {
        return input;
    }
    var errors = context.validationErrors[props.name];
    return (_jsxs("div", { className: "form_item", children: [label, _jsxs("div", { className: "form_item__input_wrap", children: [input, props.elementProps && props.elementProps.icons &&
                        props.elementProps.icons.map(function (item, index) {
                            return React.cloneElement(item, __assign(__assign({}, item.props), { key: index, setInputType: setInputType }));
                        })] }), errors &&
                _jsx("div", { className: "form_item__input_error", children: Object.keys(errors).map(function (errorType) {
                        return _jsx("span", { children: errors[errorType] }, errorType);
                    }) })] }));
};
export default InputMask;
