import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { useContext } from "react";
import { ModalContext } from "./modal-wrapper";
var ModalItem = function (props) {
    var activeTabKey = useContext(ModalContext).activeTabKey;
    return (_jsx("div", { className: activeTabKey == props.itemkey ? "modal-tabs-block active" : "modal-tabs-block", children: _jsx("div", { className: "modal-box", children: _jsx("div", { className: "modal-box-center", children: React.cloneElement(props.content, {
                    itemkey: props.itemkey,
                }) }) }) }));
};
export default ModalItem;
