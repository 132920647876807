var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { API_URL, API_VERSION, API_CLIENT_ID } from "@/configs";
import LocalStorageService from "@/app/local-storage/local-storage-service";
var Api = function () {
    var get = function (uri_1, data_1) {
        var args_1 = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args_1[_i - 2] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([uri_1, data_1], args_1, true), void 0, function (uri, data, params) {
            var url, headers, queryUri, query, requestHeaders, responseParams, response, json, res, error_1;
            if (params === void 0) { params = {}; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = Object.assign({ url: API_URL, headers: {} }, __assign({}, params));
                        url = params.url, headers = params.headers;
                        queryUri = uri;
                        if (params.query) {
                            query = objectToQuerystring(params.query);
                            queryUri = "".concat(queryUri, "?").concat(query);
                        }
                        requestHeaders = setHeaders(headers);
                        responseParams = {
                            method: "GET",
                            mode: "cors",
                            cache: "no-cache",
                            credentials: "omit",
                            headers: requestHeaders,
                            redirect: "follow",
                            referrerPolicy: "no-referrer",
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, fetch("".concat(url).concat(queryUri), responseParams)];
                    case 2:
                        response = _a.sent();
                        if (!!response.ok) return [3 /*break*/, 3];
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, response.json()];
                    case 4:
                        json = _a.sent();
                        res = __assign(__assign({}, json), { response: { status: response.status } });
                        if (typeof json.error != "undefined" && typeof params.onError == "function") {
                            params.onError(json.error);
                            return [2 /*return*/];
                        }
                        else if (typeof params.onSuccess == "function") {
                            params.onSuccess(res);
                        }
                        return [2 /*return*/, res];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_1 = _a.sent();
                        if (error_1 instanceof SyntaxError) {
                            // Unexpected token < in JSON
                            console.log('There was a SyntaxError', error_1); // TODO notify alert
                        }
                        else {
                            console.log('There was an error', error_1); // TODO notify alert
                        }
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    var post = function (uri_1, data_1) {
        var args_1 = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args_1[_i - 2] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([uri_1, data_1], args_1, true), void 0, function (uri, data, params) {
            var url, headers, credentials, requestHeaders, body, responseParams, response, json, res, error_2;
            if (params === void 0) { params = {}; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = Object.assign({ url: API_URL, headers: {} }, __assign({}, params));
                        url = params.url, headers = params.headers;
                        credentials = (params.credentials) ? params.credentials : 'omit';
                        requestHeaders = setHeaders(params.headers);
                        // Excluding Content type for correctly binding of data
                        if (data instanceof FormData) {
                            body = data;
                        }
                        else {
                            requestHeaders.set('Content-Type', 'application/json;charset=UTF-8');
                            body = JSON.stringify(data);
                        }
                        responseParams = {
                            method: "POST",
                            mode: "cors",
                            cache: "no-cache",
                            credentials: credentials,
                            headers: requestHeaders,
                            redirect: "follow",
                            referrerPolicy: "no-referrer",
                            body: body,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, fetch("".concat(url).concat(uri), responseParams)];
                    case 2:
                        response = _a.sent();
                        if (!!response.ok) return [3 /*break*/, 3];
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, response.json()];
                    case 4:
                        json = _a.sent();
                        res = __assign(__assign({}, json), { response: { status: response.status } });
                        if (typeof json.error != "undefined" && typeof params.onError == "function") {
                            params.onError(json.error);
                            return [2 /*return*/];
                        }
                        else if (typeof params.onSuccess == "function") {
                            params.onSuccess(res);
                        }
                        return [2 /*return*/, res];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_2 = _a.sent();
                        if (error_2 instanceof SyntaxError) {
                            // Unexpected token < in JSON
                            console.log('There was a SyntaxError', error_2); // TODO notify alert
                        }
                        else {
                            console.log('There was an error', error_2); // TODO notify alert
                        }
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    var setHeaders = function (customHeaders) {
        var requestHeaders = new Headers();
        requestHeaders.set('Access-Control-Allow-Credentials', 'false');
        requestHeaders.set('Authorization', "bearer ".concat(LocalStorageService.getAccessToken()));
        requestHeaders.set('Accept', 'application/json, text/html, application/xhtml+xml, application/xml;q=0.9, image/webp, */*;q=0.8');
        requestHeaders.set('Content-Type', "application/json, text/html;charset=UTF-8");
        requestHeaders.set('ClientId', "".concat(API_CLIENT_ID));
        requestHeaders.set('Api-Version', "".concat(API_VERSION));
        requestHeaders.set('Lang', LocalStorageService.getLang());
        if (customHeaders) {
            for (var _i = 0, _a = Object.entries(customHeaders); _i < _a.length; _i++) {
                var _b = _a[_i], headerName = _b[0], headerValue = _b[1];
                requestHeaders.set(headerName, headerValue);
            }
        }
        return requestHeaders;
    };
    var objectToQuerystring = function (obj, prefix) {
        if (prefix === void 0) { prefix = null; }
        var str = [];
        Object.keys(obj).map(function (key) {
            var k = prefix ? "".concat(prefix, "[").concat(key, "]") : key;
            var v = obj[key] ? obj[key] : "";
            var newItem = (v !== null && typeof v === "object")
                ? objectToQuerystring(v, k) : "".concat(encodeURIComponent(k), "=").concat(encodeURIComponent(v));
            str.push(newItem);
            return k;
        });
        return str.join("&");
    };
    var queryStringToObject = function (uri) {
        uri = uri.replace(/^\?*/, "");
        if (uri.length === 0)
            return {};
        var uriChains = uri.split("&");
        var source = {};
        uriChains.forEach(function (chain, index) {
            var _a = chain.split("="), name = _a[0], value = _a[1];
            source[name] = value;
        });
        return source;
    };
    return { get: get, post: post };
};
export default Api();
