var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useMemo, useState } from "react";
import { Form, Input, Button, Select, InputDate } from "@/app/form";
import { SettingsContext } from "@/app/settings/settings";
import Api from "@/services/api-service";
import { BalloonContext } from "@app/balloon/balloon-wrapper";
import FormSchema from "@app/form/schema/FormSchema";
import { Checkbox } from "@app/form";
import { ModalContext } from "@app/modal/modal-wrapper";
import { useNavigate } from "react-router-dom";
var AddGamePage = function (props) {
    var lang = useContext(SettingsContext).lang;
    var showMsg = useContext(BalloonContext).showMsg;
    var openModal = useContext(ModalContext).openModal;
    var navigate = useNavigate();
    var currentTimezone = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)[0];
    var _a = useState([]), errors = _a[0], setErrors = _a[1];
    var categoriesList = useMemo(function () {
        var catList = [];
        props.dicts.categories.forEach(function (item) {
            catList.push({ id: item.id, name: item["name_".concat(lang)] });
        });
        return catList;
    }, [lang, props.dicts.categories]);
    var onSubmitHandler = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var res, errorsList, fields_1;
        var values = _b.values;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, Api.post("/games/create", __assign({}, values))];
                case 1:
                    res = _c.sent();
                    errorsList = [];
                    if (typeof res.error != "undefined") {
                        errorsList.push(res.error);
                    }
                    if (typeof res.errors != "undefined") {
                        fields_1 = {
                            'title': lang == 'ru' ? 'Название игры' : 'Game name',
                            'category_id': lang == 'ru' ? 'Выберите сферу' : 'Select area',
                            'utc_zone_id': lang == 'ru' ? 'Временная зона' : 'Timezone',
                            'remind_hour': lang == 'ru' ? 'Время ежедневных напоминаний' : 'Daily reminder times',
                            'date_start': lang == 'ru' ? 'дата старта' : 'Start date',
                            'date_finish': lang == 'ru' ? 'дата окончания' : 'Finish date',
                        };
                        res.errors.forEach(function (error) {
                            errorsList.push(fields_1[error]);
                        });
                    }
                    setErrors(errorsList);
                    if (errorsList.length) {
                        return [2 /*return*/];
                    }
                    navigate("/games/play/".concat(res.data.id));
                    return [2 /*return*/];
            }
        });
    }); };
    var formSchema = new FormSchema()
        .setButtons([
        { label: { ru: 'Создать', en: 'Create' }, name: 'save', class: 'prb-button blue', type: 'submit' }
    ])
        .setSubmit(onSubmitHandler);
    var datStart = new Date();
    var finishDay = new Date(datStart.getTime() + (86400000 * 7));
    formSchema.setInitialValues({
        public_flg: 1,
        remind_hour: '21',
        date_start: datStart,
        date_finish: finishDay,
        utc_zone_id: props.zonesMap.has(currentTimezone) ? props.zonesMap.get(currentTimezone) : '',
    });
    return (_jsx("div", { className: "content-container", children: _jsx("div", { className: "borders-box-v", children: _jsxs("div", { className: "borders-box-v-content", children: [_jsx("div", { className: "quote-text mt80", children: lang == 'ru' ? 'Создать игру' : 'Create game' }), _jsx("div", { className: "games-add-page__form", children: _jsxs(Form, { schema: formSchema, children: [_jsxs("div", { className: "fieldset", style: { gridTemplateColumns: '50% 47%' }, children: [_jsx(Input, { name: "title", rules: [
                                                { type: 'required', prompt: { ru: 'Поле обязатeльно для заполнения', en: 'Field required' } },
                                            ], placeholder: lang == "ru" ? "Например, бросить курить" : "Quit smoking, for example", label: lang == 'ru' ? 'Название игры' : 'Game name' }), _jsx(Select, { name: "category_id", isEmpty: true, emptyTitle: lang == 'ru' ? 'Не выбрано' : 'Not selected', label: lang == 'ru' ? 'Выберите сферу' : 'Select area', data: categoriesList })] }), _jsxs("div", { className: "fieldset", style: { gridTemplateColumns: '31% 31% 31%' }, children: [_jsx(Select, { label: lang == 'ru' ? 'Временная зона' : 'Timezone', emptyTitle: lang == 'ru' ? 'Не выбрано' : 'Not selected', data: props.dicts.timezones, name: "utc_zone_id" }), _jsx(Select, { emptyTitle: lang == 'ru' ? 'Не выбрано' : 'Not selected', inputElement: "div", label: lang == 'ru' ? 'Время ежедневных напоминаний' : 'Daily reminder times', data: props.hoursList, name: "remind_hour" }), _jsx(Checkbox, { className: "checkbox-toggle-wrap-gold", label: lang == 'ru' ? 'Сделать игру видимой для всех' : 'Visible for all', value: '1', name: "public_flg" })] }), _jsxs("div", { className: "add-game-page-form-title", children: [lang == 'ru' && 'Выберите дату старта и окончания Игры', lang != 'ru' && 'Select the start and end date of the Game'] }), _jsxs("div", { className: "fieldset", style: {
                                        gridTemplateColumns: '50% 47%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center'
                                    }, children: [_jsx(InputDate, { label: lang == 'ru' ? 'дата старта' : 'Start date', name: "date_start" }), _jsx(InputDate, { placeholder: "", 
                                            // disableCalendar={true}
                                            label: lang == 'ru' ? 'дата окончания' : 'Finish date', name: "date_finish" })] }), _jsx("div", { children: !errors.length ? null :
                                        _jsxs("div", { className: "form-game-error", children: [lang == 'ru' && 'Пожалуйста, заполниту обязательные поля:', lang != 'ru' && 'Please fill in the required fields:', errors.map(function (error, index) {
                                                    return _jsxs("div", { children: [" - ", error] }, index);
                                                })] }) }), _jsx("div", { className: "buttons-panel", style: { alignContent: 'center', alignItems: 'center', justifyContent: 'center' }, children: _jsx(Button, { type: "submit", label: lang == 'ru' ? 'Сохранить' : 'Save', class: "submit-btn-gold" }) })] }) })] }) }) }));
};
export default AddGamePage;
