import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState } from 'react';
import { DARK_THEME } from "./types";
export var ThemeContext = createContext({
    theme: DARK_THEME,
    applyTheme: function (newTheme) {
    },
});
var ThemeWrapper = function (props) {
    var defaultTheme = localStorage.getItem('landing_theme');
    if (!defaultTheme) {
        defaultTheme = DARK_THEME;
    }
    var _a = useState(defaultTheme), theme = _a[0], setTheme = _a[1];
    var applyTheme = function (newTheme) {
        setTheme(newTheme);
        localStorage.setItem('landing_theme', newTheme);
    };
    return (_jsx(ThemeContext.Provider, { value: { theme: theme, applyTheme: applyTheme }, children: props.children }));
};
export default ThemeWrapper;
