import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect } from 'react';
import { AuthContext } from "@app/auth-wrapper";
import { SettingsContext } from "@app/settings/settings";
import { Button } from "@app/form";
import { NavLink, useNavigate } from "react-router-dom";
import { ModalContext } from "@app/modal/modal-wrapper";
import EditProfileDialog from "../dialogs/edit-profile-dialog";
import TooltipMenu from "@app/tooltip-menu";
import DropDownMenu from "@/components/profile/elements/drop-down-menu";
import DialogSlider from "../widjets/dialog-slider";
var ProfilePage = function () {
    var _a = useContext(SettingsContext), lang = _a.lang, setLang = _a.setLang;
    var _b = useContext(AuthContext), user = _b.user, logout = _b.logout, refreshAccessToken = _b.refreshAccessToken;
    var navigate = useNavigate();
    var _c = useContext(ModalContext), openDialog = _c.openDialog, closeDialog = _c.closeDialog;
    var logoutHandler = function () {
        logout();
    };
    var openEditDialog = function () {
        openDialog(_jsx(EditProfileDialog, {}));
    };
    useEffect(function () {
        openDialog(_jsx(DialogSlider, { classModifier: "profile-photos" }), [], { isMinMode: true });
    }, []);
    return (_jsx("div", { className: "content-container", children: _jsx("div", { className: "borders-box-v", children: _jsxs("div", { className: "borders-box-v-content", children: [_jsxs("div", { className: "profile-block mt80", children: [_jsx("div", { className: "profile-block-title", children: lang == 'ru' ? 'Профиль' : 'Profile' }), _jsx("div", { className: "profile-block__photo", children: _jsx(TooltipMenu, { dropDownContent: _jsx(DropDownMenu, {}), children: _jsx("div", { className: "profile-block__photo__img", style: { backgroundImage: "url('".concat(user.avatar, "')") } }) }) }), _jsx("div", { className: "profile-block_name", children: user.name }), _jsx("div", { className: "profile-block_username", children: user.username }), _jsxs("div", { className: "buttons-panel", style: { justifyContent: 'center' }, children: [_jsx(Button, { onClick: openEditDialog, class: "submit-btn-gold", label: lang == 'ru' ? 'Редактировать' : 'Edit' }), _jsx(Button, { onClick: logout, class: "submit-btn-gold", label: lang == 'ru' ? 'Выйти' : 'Logout' })] })] }), _jsx("div", { style: { maxWidth: '80%', margin: '80px auto 0 auto' }, children: _jsx(NavLink, { to: "/games/create/", className: "games-page-add-billet", children: _jsx("div", { className: "games-page-add-billet__btn", children: _jsx("div", { className: "games-page-add-billet__btn-text", children: lang == 'ru' ? 'СОЗДАТЬ СВОЮ ИГРУ' : 'create your own game' }) }) }) })] }) }) }));
};
export default ProfilePage;
