import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Outlet } from "react-router-dom";
import Modal from "@/app/modal/modal-container";
import { ThemeContext } from "@/app/themes/theme-wrapper";
import Balloon from "@/app/balloon/balloon";
import Header from "./header";
import "@/resources/styles/layout.less";
import "@/resources/styles/modifications.less";
import Footer from "@/components/layout/default/footer";
import ModalDialog from "@app/modal/modal-dialog";
var DefaultLayout = function () {
    var theme = React.useContext(ThemeContext).theme;
    return (_jsxs("div", { className: "layout ".concat(theme), children: [_jsx(Header, {}), _jsx(Outlet, {}), _jsx(Footer, {}), _jsx(Modal, {}), _jsx(Balloon, {}), _jsx(ModalDialog, {})] }));
};
export default DefaultLayout;
