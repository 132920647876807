import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "@/routes";
import ThemeWrapper from "@/app/themes";
import Settings from "@/app/settings/settings";
import AuthWrapper from "@/app/auth-wrapper";
import BalloonWrapper from "@/app/balloon/balloon-wrapper";
import ModalWrapper from "@app/modal/modal-wrapper";
var App = function () {
    return (
    // <Provider store={store}>
    _jsx(ThemeWrapper, { children: _jsx(Settings, { children: _jsx(AuthWrapper, { children: _jsx(ModalWrapper, { children: _jsx(BalloonWrapper, { children: _jsx(RouterProvider, { router: router }) }) }) }) }) })
    // </Provider>
    );
};
export default App;
