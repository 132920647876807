import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useMemo } from 'react';
import { SettingsContext } from "@app/settings/settings";
var Quotes = function () {
    var lang = useContext(SettingsContext).lang;
    var quotesList = [
        {
            quote_ru: 'Успех — это путь, а не конечная точка. Продолжай двигаться вперед.',
            author_ru: 'Винс Ломбарди',
            quote_en: 'Success is a journey, not an end point. Keep moving forward.',
            author_en: 'Vince Lombardi',
        },
        {
            quote_ru: 'Внутренняя сила раскрывается через ежедневные победы над собой.',
            author_ru: 'Мария Кюри',
            quote_en: 'Inner strength is revealed through daily victories over yourself.',
            author_en: 'Marie Curie',
        },
        {
            quote_ru: 'Каждый отказ от плохой привычки — это победа над прошлым.',
            author_ru: 'Сократ',
            quote_en: 'Every break from a bad habit is a victory over the past.',
            author_en: 'Socrates',
        },
        {
            quote_ru: 'Каждое утро — это шанс начать новую игру, где победитель — тот, кто освобождается от вредных привычек.',
            author_ru: 'Альберт Эйнштейн',
            quote_en: 'Every morning is a chance to start a new game, where the winner is the one who frees himself from bad habits.',
            author_en: 'Albert Einstein',
        },
        {
            quote_ru: 'Путь к лучшему я начинается с отказа от того, что вас удерживает.',
            author_ru: 'Опра Уинфри',
            quote_en: 'The path to your best self begins with letting go of what is holding you back.',
            author_en: 'Oprah Winfrey',
        },
        {
            quote_ru: 'Каждый день — это новый шанс отказаться от того, что тянет вас назад.',
            author_ru: 'Дейл Карнеги',
            quote_en: 'Every day is a new chance to give up what is holding you back.',
            author_en: 'Dale Carnegie',
        },
        {
            quote_ru: 'Избавьтесь от своих вредных привычек, чтобы освободить место для величия.',
            author_ru: 'Уилл Смит',
            quote_en: 'Break your bad habits to make room for greatness.',
            author_en: 'Will Smith',
        },
    ];
    var quoteRandomKey = useMemo(function () {
        return Math.floor(Math.random() * (quotesList.length - 1));
    }, []);
    var quote = quotesList[quoteRandomKey];
    if (!quote) {
        quote = quotesList[0];
    }
    return (_jsx("div", { className: "game-page-quote-wrap", children: _jsxs("div", { className: "game-page-quote", children: [_jsxs("div", { className: "game-page-quote-text", style: { textAlign: 'center' }, children: ["\u2014 ", lang == 'ru' ? quote.quote_ru : quote.quote_en] }), _jsx("div", { className: "game-page-quote-author", children: lang == 'ru' ? quote.author_ru : quote.author_en })] }) }));
};
export default Quotes;
