import Icon from "./Icon";
import PlusIcon from "./base/PlusIcon";
import EditIcon from "./base/EditIcon";
import CloneIcon from "./base/Clone";
import RefreshIcon from "./base/RefreshIcon";
import DoneIcon from "./base/DoneIcon";
import CloseIcon from "./base/CloseIcon";
import LinkToIcon from "./base/LinkToIcon";
import PdfIcon from "./base/PdfIcon";
import LoaderClockIcon from "./base/LoaderClock";
import UserIcon from "./base/UserIcon";
import "./Icon.less";
export { PlusIcon, EditIcon, CloneIcon, CloseIcon, RefreshIcon, DoneIcon, LinkToIcon, LoaderClockIcon, PdfIcon, UserIcon, };
export default Icon;
