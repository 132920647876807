import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createRoot } from 'react-dom/client';
import App from "@/components/app";
var container = document.getElementById('root');
var root = createRoot(container);
root.render(_jsx(App, {}));
if (module.hot) {
    //path.resolve(__dirname, "./src/components/app/app.tsx")
    module.hot.accept('./components/app/app.tsx', function () {
        console.log('Accepting the updated printMe module!');
    });
}
