import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import ModalTab from './modal-tab';
import ModalItem from './modal-item';
import { useContext } from "react";
import { ModalContext } from "./modal-wrapper";
import './assets/style.less';
var ModalContainer = function () {
    var _a = useContext(ModalContext), isOpen = _a.isOpen, tabs = _a.tabs, closeModal = _a.closeModal, openModal = _a.openModal, activeTabKey = _a.activeTabKey;
    return (_jsx("div", { className: isOpen ? "modal active" : "modal", onClick: function () {
            closeModal();
        }, children: _jsxs("div", { className: isOpen ? "modal-box-wrap active" : "modal-box-wrap", onClick: function (e) {
                e.stopPropagation();
            }, children: [_jsx("div", { id: "modal-tabs", children: tabs.map(function (tab, index) {
                        var title = tab.props.title ? tab.props.title : "";
                        return _jsx(ModalTab, { title: title, activeItemKey: activeTabKey, itemkey: index }, index);
                    }) }), tabs.map(function (tab, index) {
                    return _jsx(ModalItem, { activeItemKey: activeTabKey, content: tab, itemkey: index }, index);
                })] }) }));
};
export default ModalContainer;
