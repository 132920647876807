import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { ModalContext } from "./modal-wrapper";
var ModalTab = function (props) {
    var _a = useContext(ModalContext), activeTabKey = _a.activeTabKey, closeModal = _a.closeModal, setActiveTab = _a.setActiveTab;
    return (_jsxs("div", { className: activeTabKey == props.itemkey ? "modal-tabs-item active" : "modal-tabs-item", children: [_jsx("span", { className: "modal-tabs-item__link", onClick: function () { return setActiveTab(props.itemkey); }, children: props.title }), _jsx("span", { className: "modal-tabs-item__close modal-close", "item-key": props.itemkey, onClick: function () {
                    closeModal(props.itemkey);
                } })] }));
};
export default ModalTab;
