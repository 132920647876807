import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import "./content-expander.less";
var ContentExpander = function (props) {
    var _a = useState(false), isShow = _a[0], setIsShow = _a[1];
    var switchContent = function () {
        console.log(isShow);
        setIsShow(!isShow);
    };
    var contentLength = useState(props.contentLength || 50)[0];
    //
    // let content = props.children ? props.children.substring(0, contentLength) : '';
    // if (typeof props.children == "string" && props.children.length > contentLength) {
    //   content += '...';
    // }
    return (_jsxs("div", { className: "porabote-content-expander", onClick: switchContent, style: props.style, children: [_jsx("div", { className: "porabote-content-expander-text ".concat(isShow ? 'active' : ''), children: props.children }), props.children && props.children.length > contentLength &&
                _jsxs(_Fragment, { children: [isShow && props.title.hide, !isShow && props.title.show] })] }));
};
export default ContentExpander;
