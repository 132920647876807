import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Slider from "@app/slider";
import SlideItem from "./SlideItem";
var DialogSlider = function (props) {
    var testList = [
        { id: 1, url: 'https://besupr-game.com/files/gamers/IMG_4909_1722256557.JPG' },
        { id: 2, url: 'https://besupr-game.com/files/gamers/IMG_4909_1722256557.JPG' },
        { id: 3, url: 'https://besupr-game.com/files/gamers/IMG_4909_1722256557.JPG' },
    ];
    return (_jsx(Slider, { activeSlideKey: 1, classModifier: props.classModifier, children: testList.map(function (image, index) {
            return _jsx(SlideItem, { listKey: image.id, image: image }, image.id);
        }) }));
};
export default DialogSlider;
