import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from "@app/auth-wrapper";
import { useNavigate } from "react-router-dom";
import LoadingCircle from "@/components/pages/preloading-page/loading-circle";
var PrivateRoute = function (_a) {
    var children = _a.children;
    var _b = useState(false), redirect = _b[0], setRedirect = _b[1];
    var _c = useContext(AuthContext), isAuth = _c.isAuth, isAuthInited = _c.isAuthInited;
    var navigate = useNavigate();
    useEffect(function () {
        if (isAuthInited && !isAuth) {
            navigate('/auth/login/', { replace: true });
        }
    }, [isAuthInited, isAuth]);
    if (isAuthInited && isAuth) {
        return children;
    }
    return React.createElement(LoadingCircle);
};
export default PrivateRoute;
