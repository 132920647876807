import React from 'react';
import { useParams } from "react-router";
import PagePage from "@/components/pages/page/page-page";
var PagesRouter = function () {
    var component = useParams().component;
    switch (component) {
        //case "feed": return React.createElement(FeedContainer);
    }
    return React.createElement(PagePage);
};
export default PagesRouter;
