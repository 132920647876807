var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { SettingsContext } from "@/app/settings/settings";
import Api from "@/services/api-service";
import GamesListTable from "@/components/games/pages/my/templates/list-table";
import { AuthContext } from "@app/auth-wrapper";
import Quotes from "@/components/elements/quotes";
var Index = function () {
    var user = useContext(AuthContext).user;
    var navigate = useNavigate();
    var _a = useState([]), currentGames = _a[0], setCurrentGames = _a[1];
    var _b = useState([]), finishedGames = _b[0], setFinishedGames = _b[1];
    useEffect(function () {
        fetchData();
    }, []);
    var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp, currentGames, finishedGames;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Api.get("/games/action/getGames", {})];
                case 1:
                    resp = _a.sent();
                    currentGames = [];
                    finishedGames = [];
                    resp.data.forEach(function (item, index) {
                        if (item.status_id == 6) {
                            currentGames.push(item);
                        }
                        else if ([7, 8].includes(item.status_id)) {
                            finishedGames.push(item);
                        }
                    });
                    setCurrentGames(currentGames);
                    setFinishedGames(finishedGames);
                    return [2 /*return*/];
            }
        });
    }); };
    var _c = useContext(SettingsContext), lang = _c.lang, setLang = _c.setLang;
    var buttonText = (lang == 'ru') ? 'Добавить игру' : 'Add own game';
    var redirectToAdd = function () {
        navigate("/games/add");
    };
    return (_jsx("div", { className: "content-container", children: _jsx("div", { className: "borders-box-v", children: _jsxs("div", { className: "borders-box-v-content", children: [_jsxs("div", { style: { paddingTop: '50px' }, className: "game-page-title", children: [lang == 'ru' && 'Ваши текущие Игры', lang == 'en' && 'Your current games'] }), _jsx("div", { className: "pd40", children: _jsx(GamesListTable, { data: currentGames }) }), _jsx(NavLink, { to: "/games/create/", className: "games-page-add-billet", children: _jsx("div", { className: "games-page-add-billet__btn", children: _jsx("div", { className: "games-page-add-billet__btn-text", children: lang == 'ru' ? 'СОЗДАТЬ СВОЮ ИГРУ' : 'create your own game' }) }) }), _jsxs("div", { className: "game-page-title", style: { marginTop: '80px' }, children: [user.first_name, " ", user.name, " ", lang == 'ru' ? 'не сдавайся!' : 'do not give up!'] }), _jsx(Quotes, {}), _jsxs("div", { className: "game-page-title", children: [lang == 'ru' && 'Ваши завершенные игры', lang == 'en' && 'Your finished games'] }), _jsx("div", { className: "pd40", children: _jsx(GamesListTable, { data: finishedGames }) })] }) }) }));
};
export default Index;
