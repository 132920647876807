var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Button from "@/app/form/button/Button";
import { createElement } from "react";
import FormSchemaField from "./FormSchemaField";
var LANG_DEFAULT = "ru";
var FormSchema = /** @class */ (function () {
    function FormSchema() {
        var _this = this;
        this.lang = LANG_DEFAULT;
        this.fields = {};
        this.buttons = [];
        this.initialValues = {};
        this.getField = function (fieldName) {
            return _this.fields[fieldName];
        };
        this.getFields = function () {
            return Object.keys(_this.fields).map(function (fieldName) { return _this.fields[fieldName]; });
        };
        this.getButtons = function () {
            return _this.buttons;
        };
        this.setInitialValues = function (initialValues) {
            _this.initialValues = initialValues;
        };
        this.getInitialValues = function () {
            return _this.initialValues;
        };
        this.setField = function (fieldParams) {
            _this.fields[fieldParams.name] = (new FormSchemaField().init(__assign(__assign({}, fieldParams), { lang: _this.lang, key: fieldParams.name })));
            return _this;
        };
        this.setButtons = function (buttons) {
            buttons.forEach(function (buttonProps) {
                _this.buttons = [];
                buttonProps.placeholder = buttonProps.placeholder ? buttonProps.placeholder[_this.lang] : "";
                buttonProps.label = buttonProps.label ? buttonProps.label[_this.lang] : "";
                _this.buttons.push(createElement(Button, __assign(__assign({}, buttonProps), { key: buttonProps.name })));
            });
            return _this;
        };
        this.setLang = function (lang) {
            _this.lang = lang;
            return _this;
        };
        this.setSubmit = function (subminHandler) {
            _this.submit = subminHandler;
            return _this;
        };
    }
    return FormSchema;
}());
export default FormSchema;
