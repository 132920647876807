var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cell from "./cell";
var Row = function (props) {
    var style = useState(props.style || {})[0];
    var navigate = useNavigate();
    var onClickHandle = function (e) {
        if (typeof props.linkTo != "undefined") {
            var link = setLink(props.linkTo, props.record);
            navigate(link);
        }
    };
    var setLink = function (link, data) {
        var matches = link.match(/(:[A-Za-z0-9\-\_]+)/g);
        if (matches.length) {
            matches.forEach(function (propName) {
                link = link.replace(propName, data[propName.replace(':', '')]);
            });
        }
        return link;
    };
    return (_jsx("div", { className: typeof props.class == "undefined" ? "porabote-row" : "porabote-row ".concat(props.class), style: __assign({ gridTemplateColumns: props.gridTemplateColumns }, style), onClick: onClickHandle, children: React.Children.map(props.children, function (cell, index) {
            if (!cell)
                return;
            return _jsx(Cell, __assign({}, cell.props));
        }) }));
};
export default Row;
