import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { DataSourceContext } from "@app/data-source/data-source";
import StringFormat from "@app/collections/string-format";
var PaginationBlock = function (props) {
    var _a = useContext(DataSourceContext), meta = _a.meta, limit = _a.limit, setPage = _a.setPage, fetchData = _a.fetchData, pageRef = _a.pageRef;
    if (!meta) {
        return _jsx("div", {});
    }
    var showedCount = (typeof meta.offset != "undefined") ? meta.offset + meta.perPage : 'загрузка';
    var getPageHandler = function (e) {
        var pageNumber = e.target.getAttribute('data-value');
        setPage(pageNumber);
        fetchData({ dropData: true });
    };
    var buildPagesList = function () {
        var pagesCount = Math.ceil((meta.count / limit));
        var pagesList = [];
        for (var pageNumber = 1; pageNumber <= pagesCount; pageNumber++) {
            console.log(pageRef.current, pageNumber);
            pagesList.push(_jsx("div", { "data-value": pageNumber, onClick: getPageHandler, className: "data-source-pagination-pages-list__page-link ".concat(pageRef.current == (pageNumber + 1) ? 'active' : ''), children: pageNumber }, pageNumber));
        }
        return pagesList;
    };
    return (_jsxs("div", { children: [_jsx("div", { className: "data-source-pagination__info", children: _jsxs("span", { children: ["\u041F\u043E\u043A\u0430\u0437\u0430\u043D\u043E ", _jsxs("span", { className: "button_lazy-load__digital", children: [" ", showedCount] }), " \u0438\u0437", _jsx("span", { className: "button_lazy-load__digital", children: StringFormat.priceFormatInt(meta.count) }, "count")] }, "showed") }), _jsx("div", { className: "data-source-pagination-pages-list", children: buildPagesList() })] }));
};
export default PaginationBlock;
