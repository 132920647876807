var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext, useRef } from 'react';
import { FormContext } from "@/app/form";
import { FloatType } from "@/app/types";
var Input = function (props) {
    var inputRef = useRef(null);
    var context = useContext(FormContext);
    var classModify = useState(props.classModify || '')[0];
    var initValue = function () {
        var value = "";
        if (props.value) {
            value = props.value || "";
        }
        else if (context) {
            value = context.getValue(name) || "";
        }
        if (props.format) {
            value = props.format.handler(value);
        }
        return value;
    };
    var name = useState(props.name || "")[0];
    var _a = useState(initValue()), value = _a[0], setValue = _a[1];
    var _b = useState(props.type || 'string'), inputType = _b[0], setInputType = _b[1];
    var cursorPos = useRef(value.length);
    var currentValueLength = useRef(0);
    var countSpacesInLeftPart = useRef(0);
    useEffect(function () {
        setValue(context.getValue(name) || "");
    }, [props]);
    var htmlFor = "".concat(inputType, "-").concat(Math.random());
    var label = (typeof props.label != "undefined") ?
        _jsx("label", { htmlFor: htmlFor, className: "form-item__label", children: props.label }) : "";
    var disabled = false;
    if (typeof props.disabled === "function") {
        disabled = props.disabled(context);
    }
    else if (typeof props.disabled != "undefined") {
        disabled = props.disabled;
    }
    var onChangeInput = function (e) {
        var newValue = e.target.value;
        if (props.format) {
            newValue = props.format.handler(e.target.value);
        }
        cursorPos.current = e.target.selectionStart;
        setValue(newValue);
        context.setValue(name, setTypeFormat(newValue));
        if (typeof props.onChange === "function") {
            props.onChange(e, __assign(__assign({}, props), { newValue: e.target.value, context: context }));
        }
    };
    useEffect(function () {
        setCursorPositionAfterChange();
    }, [value]);
    var setCursorPositionAfterChange = function () {
        if (!props.format)
            return;
        if (inputRef === null)
            return;
        if (inputRef.current === null)
            return;
        var delta = value.length - currentValueLength.current;
        //  console.log(delta);
        delta = (delta > 0) ? delta - 1 : delta + 1;
        //console.log(value.length, currentValueLength.current, cursorPos.current, delta);
        inputRef.current.setSelectionRange(cursorPos.current + delta, cursorPos.current + delta);
        currentValueLength.current = value.length;
    };
    // removeInvalidCharacters(event) {
    //   const regex = /[|&;$%@"<>()+,]/g;
    //   const text = event.target.value;
    //   if (text.match(regex)) {
    //     event.target.selectionStart = event.target.selectionStart - 1;
    //     event.target.selectionEnd = event.target.selectionEnd - 1;
    //   }
    //   return text.replace(regex, "");
    // }
    var clickHandler = function (e) {
        cursorPos.current = inputRef.current.selectionStart;
        currentValueLength.current = value.length;
        var leftPart = value.toString().substr(0, cursorPos.current);
        countSpacesInLeftPart.current = leftPart.split(" ").length - 1;
    };
    var setTypeFormat = function (rawValue) {
        var value = rawValue;
        switch (inputType) {
            case "float":
                value = FloatType(rawValue);
        }
        return value || "";
    };
    var input = _jsx("input", { ref: inputRef, type: inputType, placeholder: props.placeholder, id: htmlFor, name: name, value: value, disabled: disabled, className: props.class || 'form-item__input', autoComplete: "off", onChange: onChangeInput, onClick: clickHandler });
    if (props.type == "hidden") {
        return input;
    }
    var errors = context.validationErrors[props.name];
    return (_jsxs("div", { className: "form-item ".concat(classModify ? classModify : ''), children: [label, _jsxs("div", { className: "form-item__input_wrap", children: [input, props.elementProps && props.elementProps.icons &&
                        props.elementProps.icons.map(function (item, index) {
                            return React.cloneElement(item, __assign(__assign({}, item.props), { key: index, setInputType: setInputType }));
                        })] }), errors &&
                _jsx("div", { className: "form-item__input_error", children: Object.keys(errors).map(function (errorType) {
                        return _jsx("span", { children: errors[errorType] }, errorType);
                    }) })] }));
};
export default Input;
