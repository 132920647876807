var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { FormContext } from "../form";
var Checkbox = function (props) {
    var context = useContext(FormContext);
    var initChecked = function () {
        if (props.checked) {
            return true;
        }
        else if (context) {
            return context.getValue(props.name) ? true : false;
        }
        return false;
    };
    var _a = useState(initChecked()), isChecked = _a[0], setIsChecked = _a[1];
    var _b = useState("checkbox-".concat(Math.random())), htmlFor = _b[0], setHtmlFor = _b[1];
    var _c = useState(context.getValue(props.name) || ""), value = _c[0], setValue = _c[1];
    useEffect(function () {
        if (props.value != value) {
            if (props.name) {
                changeStatus(!!context.getValue(props.name), null);
            }
        }
    }, [props.value]); //props.value
    var disabled = false;
    if (typeof props.disabled === "function") {
        disabled = props.disabled(props.value);
    }
    else if (props.disabled) {
        props.disabled ? props.disabled : false;
    }
    var changeStatus = function () {
        var args_1 = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args_1[_i] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([], args_1, true), void 0, function (status, event) {
            var newStatus, callbackResult;
            if (status === void 0) { status = null; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newStatus = status ? 1 : 0;
                        // If first init
                        if (typeof status != "boolean") {
                            newStatus = (!!props.value && props.value.length) ? 1 : 0;
                        }
                        setIsChecked(newStatus ? true : false);
                        if (context) {
                            context.setValue(props.name, newStatus);
                        }
                        setValue(newStatus);
                        if (typeof status != "boolean") {
                            return [2 /*return*/];
                        }
                        if (!(typeof props.onSelect == "function" && event)) return [3 /*break*/, 2];
                        return [4 /*yield*/, props.onSelect(event, __assign(__assign({}, props), { status: status }))];
                    case 1:
                        callbackResult = _a.sent();
                        if (typeof callbackResult != "undefined") {
                            setIsChecked(callbackResult.status);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    var onClickHandler = function (event) {
        var currentTarget = event.currentTarget;
        if (currentTarget) {
            var ischecked = !!(event.currentTarget.checked);
            changeStatus(ischecked, event);
        }
    };
    var onChangeHandler = function (event) {
        if (typeof props.onChange == "function") {
            props.onChange(event, props);
        }
    };
    return (_jsx("div", { className: "form-item", children: _jsxs("div", { className: props.className ? "".concat(props.className) : "form-item__checkbox-wrap", children: [props.label, _jsxs("label", { htmlFor: htmlFor, className: "checkbox-toggle-gold-label", children: [_jsx("input", { type: "checkbox", id: htmlFor, disabled: disabled, name: props.name, value: props.value || "", checked: isChecked, onClick: onClickHandler, onChange: onChangeHandler }), _jsx("span", { className: "checkbox-toggle-gold-slider" })] })] }) }));
};
export default Checkbox;
