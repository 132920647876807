import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
var Option = function (props) {
    var _a = useState(props.value), value = _a[0], setValue = _a[1];
    var title = useState(props.children)[0];
    var onMouseDown = function (e) {
        e.preventDefault();
        if (!props.isMultiple && typeof props.onSelect == "function") {
            props.onSelect(e, { newValue: value, title: title });
        }
        else if (props.isMultiple && typeof props.onSelectMultiple == "function") {
            props.onSelectMultiple(e, { newValue: value, title: title });
        }
    };
    return (_jsx("div", { onMouseDown: onMouseDown, className: "form-item__select__drop-link", children: props.children }));
};
export default Option;
