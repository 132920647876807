import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import moment from "moment";
import { SettingsContext } from "@app/settings/settings";
var PlayPanelTimer = function (props) {
    var game = props.game;
    var _a = useState(0), secondsUntilEndOfDate = _a[0], setSecondsUntilEndOfDate = _a[1];
    var lang = useContext(SettingsContext).lang;
    useEffect(function () {
        var interval = setInterval(function () {
            var d = new Date();
            var h = d.getHours();
            var m = d.getMinutes();
            var s = d.getSeconds();
            setSecondsUntilEndOfDate((24 * 60 * 60) - (h * 60 * 60) - (m * 60) - s);
        }, 1000);
        return function () { return clearInterval(interval); };
    }, []);
    return (_jsxs("div", { className: "play-page-timer-block", children: [_jsx("div", { className: "slogan-white", children: lang == 'ru' ? 'Доступно через' : 'Available via' }), _jsxs("div", { className: "slogan-gold timer-block", children: [parseInt(secondsUntilEndOfDate / 60 / 60).toString().padStart(2, "0"), ":", parseInt(secondsUntilEndOfDate / 60 % 60).toString().padStart(2, "0"), ":", parseInt(secondsUntilEndOfDate % 60).toString().padStart(2, "0")] }), _jsx("div", { className: "slogan-white", style: { padding: "20px" }, children: lang == 'ru' ? 'Ты проголосовал' : 'You voted' }), _jsx("div", { className: "slogan-white", style: { padding: "20px" }, children: moment(game.vote_today.created_at).format('DD/MM/YY HH:mm') })] }));
};
export default PlayPanelTimer;
