var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState, useEffect, useRef } from "react";
import Api from "@/services/api-service";
import ObjectMapper from "@app/collections/ObjectMapper";
import "./data-source.less";
export var DataSourceContext = createContext({
    dicts: {},
    records: [],
    filterMap: [],
    greedMap: [],
    formMap: [],
});
var DataSource = function (props) {
    var pageRef = useRef(1);
    var filterInit = function () {
        var filterDefault = {
            where: {},
            whereBetween: {},
            orWhereGrouped: [],
            whereIn: {},
            orWhere: {},
            custom: {},
        };
        if (typeof props.filterDefault == "object") {
            return ObjectMapper.mergeDeep(filterDefault, props.filterDefault);
        }
        return filterDefault;
    };
    var initMeta = {
        count: 0, // total count of records
        limit: 50,
        // offset: 0,
        //page: page,
        perPage: 0, // total count of loaded records
        lastId: 0,
    };
    var _a = useState([]), records = _a[0], setRecords = _a[1];
    var _b = useState({}), dicts = _b[0], setDicts = _b[1];
    var _c = useState(false), isDictsLoaded = _c[0], setIsDictsLoaded = _c[1];
    var _d = useState(__assign({}, initMeta)), meta = _d[0], setMeta = _d[1];
    var _e = useState([]), rels = _e[0], setRels = _e[1];
    var _f = useState(filterInit()), filter = _f[0], setFilter = _f[1];
    var isFilter = useState(typeof props.isFilter != "undefined" ? props.isFilter : true)[0];
    var _g = useState(props.isFiltersOpen || false), isFiltersOpen = _g[0], setIsFiltersOpen = _g[1];
    var _h = useState(props.relations || []), relations = _h[0], setRelations = _h[1];
    var _j = useState(true), isLoading = _j[0], setIsLoading = _j[1];
    var limit = useState(props.limit || 50)[0];
    var _k = useState(props.filterMap || []), filterMap = _k[0], setFilterMap = _k[1];
    var _l = useState(props.greedMap || []), greedMap = _l[0], setGreedMap = _l[1];
    var _m = useState(props.formMap || []), formMap = _m[0], setFormMap = _m[1];
    useEffect(function () {
        fetchData();
        fetchDicts();
    }, []);
    var toggleFilters = function () {
        setIsFiltersOpen(!isFiltersOpen);
    };
    var fetchDicts = function () { return __awaiter(void 0, void 0, void 0, function () {
        var dicts_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(typeof props.setDicts == "function")) return [3 /*break*/, 2];
                    return [4 /*yield*/, props.setDicts()];
                case 1:
                    dicts_1 = _a.sent();
                    setDictsHandle(dicts_1);
                    _a.label = 2;
                case 2:
                    setIsDictsLoaded(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var setDictsHandle = function (data) {
        setDicts(__assign(__assign({}, dicts), data));
    };
    var setFilterHandler = function (operand, value, reset) {
        if (reset === void 0) { reset = false; }
        if (!reset) {
            filter[operand] = ObjectMapper.mergeDeep(filter[operand], value);
        }
        else {
            filter[operand] = value;
        }
        fetchData({ dropData: true });
    };
    var fetchData = function () {
        var args_1 = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args_1[_i] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([], args_1, true), void 0, function (options) {
            var res;
            if (options === void 0) { options = {}; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Api.post(props.apiUrl, __assign(__assign({}, filter), { orderBy: { id: "desc" }, limit: limit, relations: relations, page: pageRef.current }), {})];
                    case 1:
                        res = _a.sent();
                        if (options.dropData) {
                            setRecords(__spreadArray([], res.data, true));
                        }
                        else {
                            setRecords(__spreadArray(__spreadArray([], records, true), res.data, true));
                            setIsLoading(false);
                        }
                        setMeta(__assign(__assign({}, meta), res.meta));
                        pageRef.current++;
                        return [2 /*return*/];
                }
            });
        });
    };
    var setPage = function (page) {
        pageRef.current = page;
    };
    return (_jsx(DataSourceContext.Provider, { value: {
            filter: filter,
            records: records,
            dicts: dicts,
            limit: limit,
            setDicts: setDictsHandle,
            isDictsLoaded: isDictsLoaded,
            fetchData: fetchData,
            isLoading: isLoading,
            setFilter: setFilterHandler,
            isFilter: isFilter,
            isFiltersOpen: isFiltersOpen,
            toggleFilters: toggleFilters,
            greedMap: greedMap,
            filterMap: filterMap,
            formMap: formMap,
            meta: meta,
            setPage: setPage,
            pageRef: pageRef,
        }, children: props.children }));
};
export default DataSource;
