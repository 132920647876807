import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import { SettingsContext } from "@/app/settings/settings";
import GamesListTable from "@/components/games/pages/index/templates/games-list-table";
import { AuthContext } from "@app/auth-wrapper";
import Quotes from "@/components/elements/quotes";
import DataSource from "@app/data-source/data-source";
var Index = function (props) {
    var user = useContext(AuthContext).user;
    var lang = useContext(SettingsContext).lang;
    return (_jsx("div", { className: "content-container", children: _jsx("div", { className: "borders-box-v", children: _jsxs("div", { className: "borders-box-v-content", children: [_jsxs("div", { style: { paddingTop: '50px' }, className: "game-page-title", children: [lang == 'ru' && 'Текущие Игры', lang == 'en' && 'Сurrent games'] }), _jsx("div", { className: "pd40", children: _jsx(DataSource, { apiUrl: "/games/get", limit: 10, children: _jsx(GamesListTable, {}) }) }), _jsx(NavLink, { to: "/games/create/", className: "games-page-add-billet", children: _jsx("div", { className: "games-page-add-billet__btn", children: _jsx("div", { className: "games-page-add-billet__btn-text", children: lang == 'ru' ? 'СОЗДАТЬ СВОЮ ИГРУ' : 'create your own game' }) }) }), _jsxs("div", { className: "game-page-title", style: { marginTop: '80px' }, children: [user.first_name, " ", user.name, " ", lang == 'ru' ? 'не сдавайся!' : 'do not give up!'] }), _jsx(Quotes, {}), _jsxs("div", { className: "game-page-title", children: [lang == 'ru' && 'Ваши завершенные игры', lang == 'en' && 'Your finished games'] })] }) }) }));
};
export default Index;
