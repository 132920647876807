import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import { ModalContext } from "@app/modal/modal-wrapper";
var ModalDialog = function () {
    var _a = useState(false), active = _a[0], setActive = _a[1];
    var _b = useContext(ModalContext), dialogContent = _b.dialogContent, dialogButtons = _b.dialogButtons, isDialogOpen = _b.isDialogOpen, closeDialog = _b.closeDialog, isMinMode = _b.isMinMode;
    var closeWrapHandler = function (e) {
        if (e.target.className === 'porabote-modal-dialog-wrap active') {
            closeDialog();
        }
    };
    return (_jsx("div", { onClick: closeWrapHandler, className: "porabote-modal-dialog-wrap ".concat(isDialogOpen ? 'active' : ''), children: _jsxs("div", { className: "porabote-modal-dialog ".concat(isDialogOpen ? 'active' : ''), children: [isMinMode &&
                    _jsx(_Fragment, { children: dialogContent }), !isMinMode &&
                    _jsxs(_Fragment, { children: [_jsx("div", { className: "porabote-modal-dialog-title", children: dialogContent }), _jsx("div", { className: "porabote-modal-dialog-buttons-panel", children: dialogButtons.map(function (item, index) { return React.cloneElement(item, { key: index }); }) })] })] }) }));
};
export default ModalDialog;
