var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Cell, Row, Table } from "@app/ui/table";
import moment from "moment";
import { SettingsContext } from "@app/settings/settings";
import { APP_DOMAIN_BASE } from "@/configs";
var PlayPageHistory = function (props) {
    var _a = useContext(SettingsContext), lang = _a.lang, setLang = _a.setLang;
    var _b = useState(props.data || []), history = _b[0], setHistory = _b[1];
    var _c = useState(props.game || null), game = _c[0], setGame = _c[1];
    var _d = useState(props.files || []), files = _d[0], setFiles = _d[1];
    useEffect(function () {
        setHistory(props.data);
    }, [props.data]);
    var daysMap = useMemo(function () {
        var dateToday = new Date();
        var dateStart = new Date(game.date_start);
        var cursorDate = new Date(game.date_finish);
        if (dateToday.getTime() < cursorDate.getTime()) {
            cursorDate = dateToday;
        }
        var isFinished = false;
        var daysMap = new Map();
        while (!isFinished) {
            daysMap.set(moment(cursorDate).format('YYYY-MM-DD'), { files: [] });
            cursorDate.setDate(cursorDate.getDate() - 1);
            if (cursorDate.getTime() < dateStart.getTime()) {
                isFinished = true;
            }
        }
        props.data.forEach(function (hist) {
            var dateKey = hist.date.substring(0, 10);
            if (daysMap.has(dateKey)) {
                daysMap.set(dateKey, __assign(__assign({}, hist), { files: [] }));
            }
        });
        files.forEach(function (file) {
            var dateKey = file.created_at.substring(0, 10);
            if (daysMap.has(dateKey)) {
                daysMap.get(dateKey).files.push(file);
            }
        });
        return Array.from(daysMap, function (_a) {
            var name = _a[0], value = _a[1];
            return (__assign({ mapDate: name }, value));
        });
    }, [props.data]);
    return (_jsxs(Table, { class: "game-table", gridTemplateColumns: "140px 100px 100px 1fr", children: [_jsxs(Row, { class: "head", children: [_jsx(Cell, { children: lang == 'ru' ? 'Дата' : 'Date' }), _jsx(Cell, { children: lang == 'ru' ? 'За Бога' : 'For God' }), _jsx(Cell, { children: lang == 'ru' ? 'За Дьявола' : 'For Devil' }), _jsx(Cell, { children: _jsx("div", { style: { textAlign: 'center' }, children: lang == 'ru' ? 'Голосовые сообщения' : 'Voices' }) })] }, "head"), daysMap.map(function (record, index) {
                return (_jsxs(Row, { record: record, children: [_jsx(Cell, { children: record.mapDate }), _jsx(Cell, { children: _jsx("div", { style: { textAlign: 'center', fontWeight: '900' }, children: record.is_win === 1 && record.value }) }), _jsx(Cell, { children: _jsx("div", { style: { textAlign: 'center', fontWeight: '900' }, children: record.is_win === 0 && record.value }) }), _jsx(Cell, { children: !record.files.length ? null : record.files.map(function (file, index) {
                                return (_jsx("div", { style: { textAlign: 'center' }, children: _jsxs("audio", { controls: true, children: [_jsx("source", { src: "https://".concat(APP_DOMAIN_BASE).concat(file.uri), type: "audio/mpeg" }, index), "Your browser does not support the audio element."] }) }));
                            }) })] }, index));
            }), props.data.length ? null :
                _jsxs(Row, { children: [_jsx(Cell, { children: lang == 'ru' ? 'Итого' : 'Total' }), _jsx(Cell, { children: "0" }), _jsx(Cell, { children: "0" }), _jsx(Cell, {})] })] }));
};
export default PlayPageHistory;
