export var APP_DOMAIN_BASE = "besupr-game.com";
export var API_URL = "https://besupr-game.com/api";
export var API_VERSION = 1;
export var API_CLIENT_ID = 4;
export var FILES_URL = "https://besupr-game.com/api/files";
export var AUTH_URL = "https://besupr-game.com/api/auth";
export var CHAT_DOMAIN = "wss://besupr-game2.com";
export var CHAT_PORT = "4000";
export var LOGIN_ACTION = "/auth/login";
export var SIGNIN_API_URL = "/auth/signIn";
export var LOGOUT_ACTION = "/auth/logout";
export var LOGOUT_API_URL = "/api/users/method/logout";
export var ACCESS_TOKEN_NAME = 'gamer_access_token';
