import ApiService from "@/services";
import axios from 'axios';
import LocalStorageService from "@app/local-storage";
import { API_CLIENT_ID, API_URL } from "@/configs";
var upload = function (file, fileInfo, options) {
    var onUploadProgressHandler = options.onUploadProgressHandler, onSuccess = options.onSuccess, uploadBefore = options.uploadBefore;
    var formData = new FormData();
    formData.append("file", file);
    for (var fieldName in fileInfo) {
        formData.append(fieldName, fileInfo[fieldName]);
    }
    var requestHeaders = setHeaders({});
    var config = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: requestHeaders,
        redirect: "follow",
        referrerPolicy: "no-referrer",
        onUploadProgress: function (progressEvent) {
            if (typeof onUploadProgressHandler == "function") {
                onUploadProgressHandler(progressEvent);
            }
        }
    };
    axios.post("".concat(API_URL).concat(fileInfo.uploadUrl), formData, config)
        .then(function (res) {
        // output.className = 'container';
        // output.innerHTML = res.data;
        if (typeof onSuccess == "function") {
            onSuccess(res);
        }
    })
        .catch(function (err) {
        // output.className = 'container text-danger';
        // output.innerHTML = err.message;
    });
};
var getFiles = function () {
    return ApiService.get("/files");
};
var setHeaders = function (customHeaders) {
    //console.log(LocalStorageService.getAccessToken());
    // const requestHeaders = new Headers();
    // requestHeaders.set('Access-Control-Allow-Credentials', 'false');
    // requestHeaders.set('Authorization', `bearer ${LocalStorageService.getAccessToken()}`);
    // requestHeaders.set('Accept', 'application/json, text/html, application/xhtml+xml, application/xml;q=0.9, image/webp, */*;q=0.8');
    // requestHeaders.set('Content-Type', `application/json, text/html;charset=UTF-8`);
    // requestHeaders.set('ClientId', `${API_CLIENT_ID}`);
    // requestHeaders.set('Api-Version', `${API_VERSION}`);
    var requestHeaders = {
        common: {
            Authorization: "bearer ".concat(LocalStorageService.getAccessToken()),
            ClientId: "".concat(API_CLIENT_ID),
            'Access-Control-Allow-Credentials': false,
            Accept: 'application/json, text/html, application/xhtml+xml, application/xml;q=0.9, image/webp, */*;q=0.8',
            //   'Content-Type': `application/json, text/html;charset=UTF-8`,
        }
    };
    if (customHeaders) {
        for (var _i = 0, _a = Object.entries(customHeaders); _i < _a.length; _i++) {
            var _b = _a[_i], headerName = _b[0], headerValue = _b[1];
            //requestHeaders.set(headerName, headerValue);
        }
    }
    return requestHeaders;
};
var FileUploadService = {
    upload: upload,
    getFiles: getFiles,
};
export default FileUploadService;
