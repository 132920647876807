var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import Icon, { LoaderClockIcon } from "@/app/ui/icons";
import { FormContext } from "@/app/form";
var loaderIcon = _jsx(Icon, { children: _jsx(LoaderClockIcon, {}) });
var Button = function (props) {
    var context = useContext(FormContext);
    var _a = useState(props.class || "prb-button"), className = _a[0], setClassName = _a[1];
    var _b = useState(props.icon || loaderIcon), icon = _b[0], setIcon = _b[1];
    var _c = useState(props.style || {}), style = _c[0], setStyle = _c[1];
    var _d = useState(false), isButtonLoading = _d[0], setIsButtonLoading = _d[1];
    var handleClick = function (event) {
        if (props.type == "submit") {
            context.onSubmit(context);
        }
        else {
            if (!isButtonLoading && typeof props.onClick !== "undefined") {
                props.onClick(event, __assign(__assign({}, props), { context: context }));
            }
        }
    };
    var isVisible = typeof props.isVisible != "undefined" ? props.isVisible(__assign({}, props)) : true;
    if (!isVisible) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("button", { className: "".concat(className, " ").concat(isButtonLoading ? "" : ""), style: __assign({ backgroundImage: icon ? "url(".concat(icon, ")") : "none" }, style), onClick: handleClick, type: "button", children: [isButtonLoading && loaderIcon, props.children &&
                props.children, props.label] }));
};
export default Button;
