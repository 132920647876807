var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Children, createContext, useEffect, useRef, useState } from 'react';
import Slide from "./Slide";
export var SliderContext = createContext({
    toNext: function () {
    },
    toPrev: function () {
    },
});
var Slider = function (_a) {
    var children = _a.children, classModifier = _a.classModifier, activeSlideKey = _a.activeSlideKey;
    var sliderRef = useRef(null);
    var _b = useState([]), slides = _b[0], setSlides = _b[1];
    var _c = useState(activeSlideKey || 0), activeSlideKey = _c[0], setActiveSlideKey = _c[1];
    useEffect(function () {
        setSlidesList();
    }, []);
    var setActiveSlideKeyHandler = function (key) {
        setActiveSlideKey(key);
    };
    var setSlidesList = function () {
        var slidesList = [];
        if (!Array.isArray(children)) {
            slidesList = [children];
        }
        else {
            slidesList = __spreadArray([], children, true);
        }
        var slides = Children.map(slidesList, function (slide) {
            if (!slide) {
                return;
            }
            return _jsx(Slide, __assign({}, slide.props, { children: slide }));
        });
        setSlides(slides);
    };
    var toNext = function () {
    };
    var toPrev = function () {
    };
    var contextValue = {
        toNext: toNext,
        toPrev: toPrev,
        sliderRef: sliderRef,
        activeSlideKey: activeSlideKey,
        setActiveSlideKeyHandler: setActiveSlideKeyHandler,
    };
    console.log(slides);
    return (_jsx(SliderContext.Provider, { value: contextValue, children: _jsxs("div", { ref: sliderRef, className: "prb-slider ".concat(classModifier), children: [slides, _jsx("div", { className: "prb-slider__button-prev" }), _jsx("div", { className: "prb-slider__button-next" })] }) }));
};
export default Slider;
