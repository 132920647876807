import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import SelectTag from "./SelectTag";
var SelectTags = function (props) {
    var removeTag = function (tagValue) {
        props.value.delete(tagValue);
        props.context.setValue(props.name, props.value, "replace");
    };
    var getTags = function () {
        var tagsElements = [];
        if (props.value) {
            props.value.forEach(function (itemvalue, valueAgain, set) {
                var element = (typeof props.setTagTitle == "function") ?
                    props.setTagTitle(itemvalue, props.storage, props.storageMap)
                    : itemvalue;
                tagsElements.push(_jsx(SelectTag, { child: element, removeTag: removeTag, value: itemvalue }, itemvalue));
            });
        }
        return tagsElements;
    };
    return (_jsx("div", { children: _jsx("div", { className: props.value && props.value.size ? "select-tags active" : "select-tags", children: getTags().map(function (item) { return item; }) }) }));
};
export default SelectTags;
