import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "@app/auth-wrapper";
import { SettingsContext } from "@app/settings/settings";
var Navbar = function (props) {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = useContext(AuthContext), isAuth = _b.isAuth, user = _b.user;
    var lang = useContext(SettingsContext).lang;
    var toggleMenu = function () {
        setIsOpen(!isOpen);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "header-panel-toggler", children: [isOpen &&
                        _jsx("div", { className: "header-panel__button right-cut", onClick: function () { return setIsOpen(false); }, children: lang == 'ru' ? 'Закрыть' : 'Close' }), !isOpen &&
                        _jsx("div", { className: "header-panel__button right-cut", onClick: function () { return setIsOpen(true); }, children: lang == 'ru' ? 'Меню' : 'Menu' })] }), _jsxs("div", { className: "header-panel ".concat(isOpen ? 'active' : ''), children: [_jsx("div", { onClick: toggleMenu, className: "header-panel__button", children: _jsx(NavLink, { to: "/", children: lang == 'ru' ? 'Главная' : 'Home' }) }), _jsx("div", { onClick: toggleMenu, className: "header-panel__button", children: _jsx(NavLink, { to: "/pages/rules/1", children: lang == 'ru' ? 'Правила' : 'Rules' }) }), _jsxs("div", { onClick: toggleMenu, className: "header-panel__button", children: [isAuth &&
                                _jsx(NavLink, { to: "/games/my/", children: lang == 'ru' ? 'Мои игры' : 'My Games' }), !isAuth &&
                                _jsx(NavLink, { to: "/auth/login", children: lang == 'ru' ? 'Мои игры' : 'Games' })] }), _jsxs("div", { onClick: toggleMenu, className: "header-panel__button", children: [isAuth &&
                                _jsx(NavLink, { to: "/profile", children: lang == 'ru' ? 'Мой профиль' : 'My profile' }), !isAuth &&
                                _jsx(NavLink, { to: "/auth/login", children: lang == 'ru' ? 'Профиль' : 'Profile' })] })] })] }));
};
export default Navbar;
