import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createBrowserRouter } from "react-router-dom";
import RouteErrorPage from "@/components/layout/errors/route-error-page";
import HomePage from "@/components/pages/home/home-page";
import DefaultLayout from "@/components/layout/default/default-layout";
import LoginPage from "@/components/auth/templates/login-page";
import PagesRouter from "@/components/pages/pages-router";
import GamesRouter from "@/components/games/games-router";
import PrivateRoute from "@/routes/private-route";
import ProfilePage from "@/components/profile/pages/profile-page";
import FaqPage from "@/components/pages/faq/FaqPage";
var router = createBrowserRouter([
    {
        path: "/",
        element: _jsx(DefaultLayout, {}),
        errorElement: _jsx(RouteErrorPage, {}),
        children: [
            { path: "/", element: _jsx(HomePage, {}) },
            { path: "/pages/:alias/:id", element: _jsx(PagesRouter, {}) },
            { path: "/faq", element: _jsx(FaqPage, {}) },
            { path: "/auth/login", element: _jsx(LoginPage, {}) },
            // {path: "/auth/reset-password", element: <PasswordResetByEmailPage/>},
        ],
    },
    {
        path: "/",
        element: _jsx(PrivateRoute, { children: _jsx(DefaultLayout, {}) }),
        errorElement: _jsx(RouteErrorPage, {}),
        children: [
            { path: "/games/:action?/:id?/:alias?", element: _jsx(GamesRouter, {}) },
            { path: "/profile", element: _jsx(ProfilePage, {}) },
        ],
    },
]);
export default router;
