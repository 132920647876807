var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { SettingsContext } from "@/app/settings/settings";
import RatingTable from "@/components/common/stats/templates/rating-table";
import ApiService from "@/services/api-service";
import Quotes from "@/components/elements/quotes";
import GamesListHomePage from "@/components/pages/home/elements/games-list-home-page";
var HomePage = function () {
    var navigate = useNavigate();
    var lang = useContext(SettingsContext).lang;
    var _a = useState([]), currentGames = _a[0], setCurrentGames = _a[1];
    useEffect(function () {
        getCurrentGames();
    }, []);
    var getCurrentGames = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ApiService.get("/games/action/getCommonCurrentGames")];
                case 1:
                    res = _a.sent();
                    if (res.data) {
                        setCurrentGames(res.data);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "content-container", style: { top: '-10px' }, children: [_jsx(RatingTable, {}), _jsx("div", { className: "borders-box-v", children: _jsxs("div", { className: "borders-box-v-content", children: [_jsxs("div", { className: "home-page_logo-wrap", children: [_jsx("div", { children: _jsx("img", { alt: "BESUPR GAME", className: "home-page_logo-img", src: "https://besupr-game.com/images/design/logo.png" }) }), _jsx("div", { className: "home-page_logo-slogan", children: lang == 'ru' ? 'Игра — это твоя жизнь' : 'The game— is your life' })] }), _jsxs("div", { className: "home-page_slogans", children: [_jsx("div", { className: "home-page_slogans-item", children: lang == 'ru' ? 'Бросить курить — сюда' : 'Quit smoking - here' }), _jsx("div", { className: "home-page_slogans-item", children: lang == 'ru' ? 'Проблемы с алкоголем — сюда' : 'Problems with alcohol - here' }), _jsx("div", { className: "home-page_slogans-item", children: lang == 'ru' ? 'Вредные привычки — сюда' : 'Bad habits - here' })] }), _jsx("div", { className: "home-page_slogans mobile", children: "\u0411\u0440\u043E\u0441\u0438\u0442\u044C \u043A\u0443\u0440\u0438\u0442\u044C, \u043F\u0438\u0442\u044C \u0430\u043B\u043A\u043E\u0433\u043E\u043B\u044C, \u043F\u043E\u0431\u0435\u0434\u0438\u0442\u044C \u0432\u0440\u0435\u0434\u043D\u044B\u0435 \u043F\u0440\u0438\u0432\u044B\u0447\u043A\u0438" }), _jsx("div", { className: "home-page-empty-billet__wrap", children: _jsx("div", { className: "home-page-empty-billet" }) }), _jsx("div", { className: "home-page_slogans", style: { justifyContent: 'center' }, children: _jsx("div", { className: "home-page_slogans-item", children: lang == 'ru' ? 'Питание, Похудение, начать делать Зарядку, поменять характер, поменять поступки.' :
                                    'Nutrition, Weight loss, start doing exercises, change your character, change your actions.' }) }), _jsx("div", { className: "home-page_slogans mobile", children: _jsx("div", { className: "home-page_slogans-item", children: lang == 'ru' ? 'Питание, Похудение, начать делать Зарядку, поменять характер, поменять поступки.' :
                                    'Nutrition, Weight loss, start doing exercises, change your character, change your actions.' }) }), _jsx("div", { className: "home-page_logo-slogan", children: lang == 'ru' ? ' Не нравится то, что имеешь - измени то, что делаешь.' :
                                'If you don\'t like what you have, change what you are doing.' }), _jsx(Quotes, {}), _jsx("div", { className: "home-page-add-billet__wrap", children: _jsx(NavLink, { to: "/games/create/", className: "home-page-add-billet", children: _jsx("div", { className: "home-page-add-billet__btn", children: _jsx("div", { className: "home-page-add-billet__btn-text", children: lang == 'ru' ? 'СОЗДАТЬ СВОЮ ИГРУ' : 'create your own game' }) }) }) }), currentGames.length &&
                            _jsxs(_Fragment, { children: [_jsxs("div", { style: { paddingTop: '50px' }, className: "game-page-title", children: [lang == 'ru' && 'Текущие Игры', lang == 'en' && 'Current games'] }), _jsx(GamesListHomePage, { data: currentGames })] })] }) }), _jsxs("div", { className: "home-motivation-text", children: [_jsx("div", { className: "quote-text-big gold", children: lang == 'ru' ? 'Действуй!' :
                            'Take action!' }), _jsxs("div", { className: "quote-text-small", style: { color: '#fff' }, children: [lang == 'ru' ? 'Каждый влияет только через свои действия. Смотри что происходит сейчас.' :
                                'Everyone influences only through their actions. Look what\'s happening now.', _jsx("b", {}), lang == 'ru' ? 'Ты непосредсвенно влияешь на то, что происходит на Земле своими действиями.' :
                                'You directly influence what happens on Earth through your actions.'] })] }), _jsx("div", { style: { marginBottom: '90px' }, children: _jsx("div", { className: "home-page-add-billet_second__wrap", children: _jsx(NavLink, { to: "/games/create/", className: "home-page-add-billet_second", children: _jsx("div", { className: "home-page-add_second-billet__btn", children: _jsx("div", { className: "home-page-add-billet_second__btn-text", children: lang == 'ru' ? 'СОЗДАТЬ СВОЮ ИГРУ' : 'create your own game' }) }) }) }) })] }));
};
export default HomePage;
