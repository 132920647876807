import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import "../assets/style.less";
import ViewIcon from "@app/ui/icons/base/ViewIcon";
import Icon from "@app/ui/icons";
import TrashIcon from "@app/ui/icons/base/TrashIcon";
import DownloadIcon from "@app/ui/icons/base/DownloadIcon";
import CropIcon from "@app/ui/icons/base/CropIcon";
import Files from "@app/files";
import AvatarUploader from "@app/files/avatar-uploader/avatar-uploader";
import { AuthContext } from "@app/auth-wrapper";
var DropDownMenu = function () {
    var _a = useContext(AuthContext), user = _a.user, refreshAccessToken = _a.refreshAccessToken;
    var avatarUploadCallback = function (res) {
        refreshAccessToken();
    };
    return (_jsxs("div", { className: "profile__drop-down-menu", children: [_jsxs("div", { className: "profile__drop-down-menu__item", children: [_jsx(Icon, { className: "profile__drop-down-menu__item-icon", size: 20, fill: "#e7e7e7", fillHover: "#ffffff", children: _jsx(ViewIcon, {}) }), "\u041E\u0442\u043A\u0440\u044B\u0442\u044C \u0444\u043E\u0442\u043E\u0433\u0440\u0430\u0444\u0438\u044E"] }), _jsx(Files, { uploadUrl: '/gamers/action/uploadAvatar', fileInfo: { record_id: user.id, model_name: 'Gamer' }, children: _jsx(AvatarUploader, { uploadCallback: avatarUploadCallback, className: "avatar_upload_input", children: _jsxs("div", { className: "profile__drop-down-menu__item", children: [_jsx(Icon, { className: "profile__drop-down-menu__item-icon", size: 22, fill: "#e7e7e7", fillHover: "#ffffff", children: _jsx(DownloadIcon, {}) }), "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C \u0444\u043E\u0442\u043E\u0433\u0440\u0430\u0444\u0438\u044E"] }) }) }), _jsxs("div", { className: "profile__drop-down-menu__item", children: [_jsx(Icon, { className: "profile__drop-down-menu__item-icon", size: 20, fill: "#e7e7e7", fillHover: "#ffffff", children: _jsx(CropIcon, {}) }), "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u043C\u0438\u043D\u0438\u0430\u0442\u044E\u0440\u0443"] }), _jsxs("div", { className: "profile__drop-down-menu__item", children: [_jsx(Icon, { className: "profile__drop-down-menu__item-icon", size: 20, fill: "#e7e7e7", fillHover: "#ffffff", children: _jsx(TrashIcon, {}) }), "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0444\u043E\u0442\u043E\u0433\u0440\u0430\u0444\u0438\u044E"] })] }));
};
export default DropDownMenu;
