var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import UploadService from "@app/files/uploader/upload-service";
import { APP_DOMAIN_BASE } from "@/configs";
import { ModalContext } from "@app/modal/modal-wrapper";
import ApiService from "@/services/api-service";
var AvatarUploaderPreviewDialog = function (props) {
    var _a = useState(true), isUploading = _a[0], setIsUploading = _a[1];
    var _b = useState(null), file = _b[0], setFile = _b[1];
    var _c = useState(0), uploadingPercent = _c[0], setUploadingPercent = _c[1];
    var selectedFile = props.selectedFile, uploadOptions = props.uploadOptions;
    var closeDialog = useContext(ModalContext).closeDialog;
    var onUploadProgressHandler = function (progressEvent) {
        setUploadingPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    };
    var acceptUpaload = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, ApiService.post((_a = props.uploadOptions) === null || _a === void 0 ? void 0 : _a.uploadUrl, {
                        file_id: file.id,
                        confirm: 1,
                    })];
                case 1:
                    res = _b.sent();
                    if (typeof props.uploadCallback == "function") {
                        props.uploadCallback(res);
                    }
                    closeDialog();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        uploadFile();
    }, []);
    var uploadFile = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            UploadService.upload(selectedFile, uploadOptions, {
                onUploadProgressHandler: onUploadProgressHandler,
                onSuccess: function (res) {
                    setFile(res.data.data);
                    setIsUploading(false);
                }
            });
            return [2 /*return*/];
        });
    }); };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "avatar-upload-percents", children: [uploadingPercent > 0 && uploadingPercent < 100 && "".concat(uploadingPercent, " %"), !isUploading && file &&
                    _jsxs(_Fragment, { children: [_jsxs("div", { className: "porabote-modal-dialog-buttons-panel", style: { marginTop: '40px' }, children: [_jsx("div", { className: "porabote-modal-dialog-button", onClick: closeDialog, children: "\u041E\u0442\u043C\u0435\u043D\u0430" }), _jsx("div", { className: "porabote-modal-dialog-button", onClick: acceptUpaload, children: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C" })] }), _jsx("div", { className: "avatar-upload-photo-wrap", children: _jsx("img", { className: "avatar-upload-photo", src: "https://".concat(APP_DOMAIN_BASE).concat(file.uri) }) })] })] }) }));
};
export default AvatarUploaderPreviewDialog;
