var ObjectMapper = /** @class */ (function () {
    function ObjectMapper() {
        var _this = this;
        this.get = function (path, source) {
            var pathArray = [];
            if (typeof path == "string") {
                pathArray = path.split('.');
            }
            else {
                pathArray = [path];
            }
            var cursor = source;
            pathArray.map(function (key) {
                if (!cursor || typeof cursor[key] == "undefined") {
                    cursor = null;
                }
                else {
                    cursor = cursor[key];
                }
            });
            return cursor;
        };
        this.isObject = function (item) {
            return (item && typeof item === 'object' && !Array.isArray(item));
        };
        this.isArray = function (item) {
            return (item && Array.isArray(item));
        };
        this.spliceByKey = function (target, name, index) {
            var splits = name.split('.');
            var cursor = target;
            for (var key in splits) {
                cursor = cursor[splits[key]];
            }
            cursor.splice(index, 1);
            return target;
        };
        this.pushValue = function (target, name, value) {
            var splits = name.split('.');
            var cursor = target;
            for (var key in splits) {
                console.log(key);
                cursor = cursor[splits[key]];
            }
            cursor.push(value);
            return target;
        };
        this.replaceValue = function (target, source) {
            var _a, _b;
            for (var key in source) {
                if (_this.isObject(source[key])) {
                    if (!target[key])
                        Object.assign(target, (_a = {}, _a[key] = {}, _a));
                    _this.replaceValue(target[key], source[key]);
                }
                else if (_this.isArray(source[key])) {
                    target[key] = source[key];
                }
                else {
                    Object.assign(target, (_b = {}, _b[key] = source[key], _b));
                }
            }
            return target;
        };
        this.mergeValues = function (target, source) {
            var _a, _b, _c;
            for (var key in source) {
                if (_this.isObject(source[key])) {
                    if (!target[key])
                        Object.assign(target, (_a = {}, _a[key] = {}, _a));
                    _this.mergeValues(target[key], source[key]);
                }
                else if (_this.isArray(source[key])) {
                    if (!target[key])
                        Object.assign(target, (_b = {}, _b[key] = [], _b));
                    _this.mergeValues(target[key], source[key]);
                }
                else {
                    Object.assign(target, (_c = {}, _c[key] = source[key], _c));
                }
            }
            return target;
        };
        /*
        * Convert string path like 'one.1.two' to Object like {"one": {"1": {"two": 'testValue'}}}
        * */
        this.buildPathBranch = function (path, value) {
            var splits = path.split('.');
            splits.reverse();
            var target = {};
            splits.map(function (key, index) {
                if (index == 0) {
                    target[key] = value;
                }
                else {
                    var arr = isNaN(parseInt(key)) ? {} : [];
                    arr[key] = target;
                    target = arr;
                }
            });
            return target;
        };
        this.deleteValue = function (target, name) {
            var splits = name.split('.');
            var cursor = target;
            for (var key in splits) {
                if (!_this.isObject(cursor[splits[key]])) {
                    delete cursor[splits[key]];
                    break;
                }
                cursor = cursor[splits[key]];
            }
            return target;
        };
        this.setPatch = function (suorce, name, value) {
            var splits = name.split('.');
            var cursor = suorce;
            splits.map(function (item, index) {
                if (splits.length == index + 1) {
                    cursor[item] = value;
                }
                else {
                    cursor[item] = (typeof cursor[item] == "undefined") ? {} : cursor[item];
                    cursor = cursor[item];
                }
            });
            return suorce;
        };
    }
    ObjectMapper.prototype.setValue = function (name, value, values, mode) {
        if (!mode)
            mode = 'merge';
        var valueBranch = this.buildPathBranch(name, value);
        switch (mode) {
            case 'merge': //if value is string or object
                values = this.mergeValues(values, valueBranch);
                break;
            case 'delete': // unset value ( with key )
                values = this.deleteValue(values, name);
                break;
            case 'replace': // if value is array - target array will be replaced to source array
                values = this.replaceValue(values, valueBranch);
                break;
            case 'push': // if value is array - source array will be added to target array
                values = this.pushValue(values, name, value);
                break;
            case 'spliceByKey': // if value is array - source array will be removed from target array
                values = this.spliceByKey(values, name, value);
                break;
            case 'patch': // Set new path in source
                values = this.setPatch(values, name, value);
                break;
        }
        return Object.assign(values, values);
    };
    ObjectMapper.prototype.sortObject = function (source) {
        var target = {};
        return Object.keys(source).sort().reduce(function (obj, key) {
            obj[key] = source[key];
            return obj;
        }, {});
    };
    return ObjectMapper;
}());
export default new ObjectMapper();
