var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createElement } from "react";
import Input from "../Input/Input";
import Select from "../Select/Select";
import InputMask from "../Input/input-mask";
import Checkbox from "../checkbox/checkbox";
import InputDate from "../Input/InputDate";
var FormSchemaField = /** @class */ (function () {
    function FormSchemaField() {
        var _this = this;
        this.init = function (props) {
            props.placeholder = props.placeholder ? props.placeholder[props.lang] : "";
            props.label = props.label ? props.label[props.lang] : "";
            _this.props = props;
            _this.createComponent();
            return _this;
        };
        this.createComponent = function () {
            if (_this.props.component == "input") {
                _this.component = createElement(Input, _this.props);
            }
            else if (_this.props.component == "inputMask") {
                _this.component = createElement(InputMask, _this.props);
            }
            else if (_this.props.component == "select") {
                _this.component = createElement(Select, _this.props);
            }
            else if (_this.props.component == "checkbox") {
                _this.component = createElement(Checkbox, _this.props);
            }
            else if (_this.props.component == "input-date") {
                _this.component = createElement(InputDate, __assign({ disableCalendar: true }, _this.props));
            }
        };
        this.setProp = function (propName, value) {
            var newProp = {};
            newProp[propName] = value;
            _this.props = Object.assign(__assign({}, _this.props), newProp);
            _this.createComponent();
        };
        this.render = function () {
            return _this.component;
        };
    }
    return FormSchemaField;
}());
export default FormSchemaField;
