import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useRef, useState } from 'react';
export var TooltipMenuContext = createContext({
    openTooltipMenu: function () {
    },
    closeTooltipMenu: function () {
    },
});
var TooltipMenu = function (props) {
    var refWrap = useRef(null);
    var _a = useState(false), isActive = _a[0], setIsActive = _a[1];
    var _b = useState(false), isMoreSpaceOnTop = _b[0], setIsMoreSpaceOnTop = _b[1];
    var openTooltipMenu = function () {
        recalcPosition();
        setIsActive(true);
    };
    var recalcPosition = function () {
        if (!refWrap.current) {
            return;
        }
        var wrapPosition = refWrap.current.getBoundingClientRect();
        var centralPointPositionY = wrapPosition.height / 2 + wrapPosition.top;
        //   const centralPointPositionX = wrapPosition.width / 2 + wrapPosition.left;
        var windowSize = getWindowDimensions();
        var isMoreSpaceOnTop = (centralPointPositionY > windowSize.height / 2);
        setIsMoreSpaceOnTop(isMoreSpaceOnTop);
        // const isSpaceMoreOnRight = (centralPointPositionX < windowSize.width / 2);
        // Если расстояние сверху больше чем снизу
    };
    function getWindowDimensions() {
        var width = window.innerWidth, height = window.innerHeight;
        return {
            width: width,
            height: height
        };
    }
    var closeTooltipMenu = function () {
        setIsActive(false);
    };
    return (_jsx(TooltipMenuContext.Provider, { value: {
            openTooltipMenu: openTooltipMenu,
            closeTooltipMenu: closeTooltipMenu,
        }, children: _jsxs("div", { onMouseOver: function () { return openTooltipMenu(); }, onMouseOut: function () { return closeTooltipMenu(); }, className: "prb-tooltip-menu__wrap top", ref: refWrap, children: [_jsx("div", { className: "prb-tooltip-menu__drop-down-block ".concat(isActive ? 'active' : '', " ").concat(isMoreSpaceOnTop ? 'top' : 'bottom'), children: props.dropDownContent }), props.children] }) }));
};
export default TooltipMenu;
