var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, useEffect, useState } from 'react';
import Balloon from "@app/balloon/balloon";
var initValues = {
    msgs: [],
    closeMsg: function (unique) { }
};
export var BalloonContext = createContext(initValues);
var AuthWrapper = function (props) {
    var _a = useState([]), msgs = _a[0], setMsgs = _a[1];
    useEffect(function () {
        //pushMsg();
    }, [msgs]);
    var showMsg = function (title, type) {
        if (type === void 0) { type = 'notice'; }
        setMsgs(__spreadArray(__spreadArray([], msgs, true), [
            { title: title, type: type, unique: Math.floor(Math.random() * 1000000) },
        ], false));
    };
    var closeMsg = function (key) {
        var msgsNew = msgs.filter(function (item) {
            return item.unique === key ? false : true;
        });
        setMsgs(__spreadArray([], msgsNew, true));
    };
    return (_jsx(BalloonContext.Provider, { value: { msgs: msgs, showMsg: showMsg, closeMsg: closeMsg }, children: _jsxs(_Fragment, { children: [props.children, _jsx(Balloon, { msgs: msgs }, "balloon")] }) }));
};
export default AuthWrapper;
