var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useEffect, useState } from 'react';
var initialAuth = {
    isOpen: false,
    isDialogOpen: false,
    activeTabKey: 0,
    tabs: [],
    closeModal: function () {
        console.log(close);
    },
    openModal: function () {
        console.log(open);
    },
    openDialog: function (content, buttons, config) {
        console.log(open);
    },
    closeDialog: function () {
        console.log(close);
    },
};
export var ModalContext = createContext(initialAuth);
var ModalWrapper = function (props) {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = useState([]), tabs = _b[0], setTabs = _b[1];
    var _c = useState(0), activeTabKey = _c[0], setActiveTabKey = _c[1];
    var _d = useState(false), isMinMode = _d[0], setIsMinMode = _d[1];
    var _e = useState(false), isDialogOpen = _e[0], setIsDialogOpen = _e[1];
    var _f = useState([]), dialogButtons = _f[0], setDialogButtons = _f[1];
    var _g = useState(""), dialogContent = _g[0], setDialogContent = _g[1];
    useEffect(function () {
    }, [isOpen]);
    var close = function () {
        setTabs([]);
        setIsOpen(false);
    };
    var open = function (content) {
        setTabs(__spreadArray(__spreadArray([], tabs, true), [
            content,
        ], false));
        setIsOpen(true);
    };
    var setActiveTabKeyHandler = function (keyId) {
        setActiveTabKey(keyId);
    };
    var openDialog = function (content, buttons, configs) {
        if (configs.isMinMode !== undefined) {
            setIsMinMode(configs.isMinMode);
        }
        setDialogContent(content);
        setDialogButtons(buttons || []);
        setIsDialogOpen(true);
        document.documentElement.classList.add("no-scroll");
    };
    var closeDialog = function () {
        setDialogContent("");
        setDialogButtons([]);
        setIsDialogOpen(false);
        document.documentElement.classList.remove("no-scroll");
    };
    return (_jsx(ModalContext.Provider, { value: {
            isOpen: isOpen,
            activeTabKey: activeTabKey,
            tabs: tabs,
            setActiveTab: setActiveTabKeyHandler,
            closeModal: close,
            openModal: open,
            isDialogOpen: isDialogOpen,
            openDialog: openDialog,
            closeDialog: closeDialog,
            dialogContent: dialogContent,
            dialogButtons: dialogButtons,
            isMinMode: isMinMode,
        }, children: props.children }));
};
export default ModalWrapper;
