import { ACCESS_TOKEN_NAME } from "@/configs";
var LocalStorageService = function () {
    var setAccessToken = function (token) {
        localStorage.setItem(ACCESS_TOKEN_NAME, token);
    };
    var getAccessToken = function () {
        var accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);
        if (typeof accessToken === "string" && accessToken.length > 0) {
            return accessToken;
        }
        return null;
    };
    var getLang = function () {
        var lang = localStorage.getItem('lang');
        if (typeof lang === "string") {
            return lang;
        }
        return 'en';
    };
    var removeAccessToken = function () {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
    };
    return {
        setAccessToken: setAccessToken,
        getAccessToken: getAccessToken,
        removeAccessToken: removeAccessToken,
        getLang: getLang,
    };
};
export default LocalStorageService();
