var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useState } from "react";
import { Form, Input, Button } from "@/app/form";
import { SettingsContext } from "@/app/settings/settings";
import Api from "@/services/api-service";
import { BalloonContext } from "@app/balloon/balloon-wrapper";
import FormSchema from "@app/form/schema/FormSchema";
import { AuthContext } from "@app/auth-wrapper";
import { ModalContext } from "@app/modal/modal-wrapper";
var EditProfileDialog = function (props) {
    var lang = useContext(SettingsContext).lang;
    var showMsg = useContext(BalloonContext).showMsg;
    var closeDialog = useContext(ModalContext).closeDialog;
    var _a = useContext(AuthContext), user = _a.user, refreshAccessToken = _a.refreshAccessToken;
    var _b = useState([]), errors = _b[0], setErrors = _b[1];
    var onSubmitHandler = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var res, errorsList, fields_1;
        var values = _b.values;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, Api.post("/gamers/action/edit", __assign({}, values))];
                case 1:
                    res = _c.sent();
                    errorsList = [];
                    if (typeof res.error != "undefined") {
                        errorsList.push(res.error);
                    }
                    if (typeof res.errors != "undefined") {
                        fields_1 = {
                            'name': lang == 'ru' ? 'Название игры' : 'Game name',
                            'username': lang == 'ru' ? 'Выберите сферу' : 'Select area',
                        };
                        res.errors.forEach(function (error) {
                            errorsList.push(fields_1[error]);
                        });
                    }
                    setErrors(errorsList);
                    if (errorsList.length) {
                        return [2 /*return*/];
                    }
                    refreshAccessToken();
                    closeDialog();
                    return [2 /*return*/];
            }
        });
    }); };
    var formSchema = new FormSchema()
        .setButtons([
        { label: { ru: 'Сохранить', en: 'Save' }, name: 'save', class: 'prb-button blue', type: 'submit' }
    ])
        .setSubmit(onSubmitHandler);
    var fi = user.name.split(' ');
    formSchema.setInitialValues({
        first_name: (fi[1]) ? fi[1] : fi[0],
        last_name: fi[1] ? fi[0] : '',
        username: user.username,
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", { style: { paddingBottom: '20px' }, children: lang == 'ru' ? 'Редактировать профиль' : 'Edit profile' }), _jsx("div", { children: _jsxs(Form, { schema: formSchema, children: [_jsx(Input, { name: "username", classModify: "input-medium", rules: [{ type: 'required', prompt: { ru: 'Поле обязатeльно для заполнения', en: 'Field required' } },], label: lang == 'ru' ? 'Никнейм' : 'Nickname' }), _jsx(Input, { name: "first_name", classModify: "input-medium", rules: [{ type: 'required', prompt: { ru: 'Поле обязатeльно для заполнения', en: 'Field required' } },], label: lang == 'ru' ? "Имя" : "Name" }), _jsx(Input, { name: "last_name", classModify: "input-medium", rules: [{ type: 'required', prompt: { ru: 'Поле обязатeльно для заполнения', en: 'Field required' } },], label: lang == 'ru' ? "Фамилия" : "Last Name" }), _jsx("div", { children: !errors.length ? null :
                                _jsxs("div", { className: "form-game-error", children: [lang == 'ru' && 'Пожалуйста, заполниту обязательные поля:', lang != 'ru' && 'Please fill in the required fields:', errors.map(function (error, index) {
                                            return _jsxs("div", { children: [" - ", error] }, index);
                                        })] }) }), _jsx("div", { className: "buttons-panel", style: { alignContent: 'center', alignItems: 'center', justifyContent: 'center' }, children: _jsx(Button, { type: "submit", label: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C", class: "submit-btn-gold" }) })] }) })] }));
};
export default EditProfileDialog;
