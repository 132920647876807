import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useParams } from "react-router";
import PlayPageContainer from "@/components/games/pages/play-page/play-page-container";
import GamesIndex from "@/components/games/pages/index/index-container";
import AddGameContainer from "@/components/games/pages/add-game/add-game-container";
import MyContainer from "@/components/games/pages/my/my-container";
var GamesRouter = function () {
    var action = useParams().action;
    if (action == "play") {
        return _jsx(PlayPageContainer, {});
    }
    else if (action == "create") {
        return _jsx(AddGameContainer, {});
    }
    else if (action == "my") {
        return _jsx(MyContainer, {});
    }
    return _jsx(GamesIndex, {});
};
export default GamesRouter;
