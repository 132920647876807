import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { Cell, Row, Table } from "@app/ui/table";
import moment from "moment";
import { SettingsContext } from "@app/settings/settings";
import { ContentExpander } from "@app/accordion";
var ListTable = function (props) {
    var _a = useContext(SettingsContext), lang = _a.lang, setLang = _a.setLang;
    if (!props.data) {
        return _jsx("div", { children: "\u0417\u0430\u0433\u0440\u0443\u0436\u0430\u044E" });
    }
    return (_jsxs(Table, { class: "game-table", gridTemplateColumns: "120px minmax(200px, 1fr) 140px 140px 140px 140px", children: [_jsxs(Row, { class: "head", children: [_jsxs(Cell, { children: [lang == 'ru' && '№ Игры', lang == 'en' && 'Game number'] }), _jsxs(Cell, { children: [lang == 'ru' && 'Описание', lang == 'en' && 'Description'] }), _jsxs(Cell, { children: [lang == 'ru' && 'За Бога', lang == 'en' && 'For God'] }), _jsxs(Cell, { children: [lang == 'ru' && 'За Дьявола', lang == 'en' && 'For Devil'] }), _jsxs(Cell, { children: [lang == 'ru' && 'Начало', lang == 'en' && 'Start'] }), _jsxs(Cell, { children: [lang == 'ru' && 'Завершение', lang == 'en' && 'Finish'] })] }, "head"), props.data.map(function (record) {
                return (_jsxs(Row, { linkTo: "/games/play/:id", record: record, children: [_jsx(Cell, { children: _jsxs("b", { children: [record.id, "/", moment(record.created_at).format('mm/YY')] }) }), _jsx(Cell, { style: {
                                overflow: 'visible',
                                cursor: 'pointer',
                                padding: '0',
                                maxHeight: '300px'
                            }, children: _jsx(ContentExpander, { contentLength: 50, style: { padding: '20px' }, title: {
                                    show: lang == 'ru' ? 'Ещё...' : 'More...',
                                    hide: lang == 'ru' ? '...Скрыть' : '...Hide'
                                }, children: record.title }) }), _jsx(Cell, { children: record.count_positive }), _jsx(Cell, { children: record.count_negative }), _jsx(Cell, { children: moment(record.date_start).format('DD/MM/YY') }), _jsx(Cell, { children: moment(record.date_finish).format('DD/MM/YY') })] }, record.id));
            }), props.data.length ? null :
                _jsxs(Row, { children: [_jsxs(Cell, { children: [lang == 'ru' && 'Итого', lang == 'en' && 'Total'] }), _jsx(Cell, {}), _jsx(Cell, { children: "0" }), _jsx(Cell, { children: "0" }), _jsx(Cell, {}), _jsx(Cell, {})] })] }));
};
export default ListTable;
