var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PasswordEyeIcon from "@/app/ui/icons/forms/PasswordEyeIcon";
import Icon from "@/app/ui/icons";
import { AuthContext } from "@/app/auth-wrapper/auth-wrapper";
import { SettingsContext } from "@/app/settings/settings";
import FormSchema from "@/app/form/schema/FormSchema";
import { BalloonContext } from "@/app/balloon/balloon-wrapper";
import { SIGNIN_API_URL } from "@/configs";
import ApiService from "@/services/api-service";
import { ModalContext } from "@app/modal/modal-wrapper";
import TelegramDialog from "@/components/auth/templates/social-media/telegram-dialog";
import { Helmet } from "react-helmet";
var LoginPage = function () {
    var _a = useContext(AuthContext), isAuth = _a.isAuth, login = _a.login;
    var navigate = useNavigate();
    if (isAuth) {
        navigate("/profile");
    }
    var _b = useState(null), telegramScript = _b[0], setTelegramScript = _b[1];
    var telegramWrapperRef = useRef(null);
    useEffect(function () {
        window.TelegramLoginWidget = {
            callbackOnAuth: function (user) {
                getTokens(user);
            }
        };
        var getTokens = function (user) { return __awaiter(void 0, void 0, void 0, function () {
            var tokens;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ApiService.post('/auth/oauthTelegram', { user: __assign({}, user) })];
                    case 1:
                        tokens = _a.sent();
                        login(tokens.access_token);
                        return [2 /*return*/];
                }
            });
        }); };
        var scriptElement = document.createElement('script');
        scriptElement.src = 'https://telegram.org/js/telegram-widget.js?22';
        scriptElement.setAttribute('data-telegram-login', 'BesuprGameBot');
        scriptElement.setAttribute('data-request-access', 'write');
        scriptElement.setAttribute('data-size', 'large');
        scriptElement.setAttribute('data-onauth', 'TelegramLoginWidget.callbackOnAuth(user)');
        // scriptElement.setAttribute('data-auth-url', 'paste-redirect-url');
        scriptElement.async = true;
        telegramWrapperRef.current.appendChild(scriptElement);
    }, []);
    var onTelegramAuth = function (user) {
        console.log(user);
        console.log(123);
    };
    var _c = useContext(SettingsContext), lang = _c.lang, setLang = _c.setLang;
    var signIn = useContext(AuthContext).signIn;
    var openModal = useContext(ModalContext).openModal;
    var showMsg = useContext(BalloonContext).showMsg;
    var openTelegramDialog = function () {
        openModal(_jsx(TelegramDialog, {}));
    };
    var signInHandler = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var res;
        var values = _b.values;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, ApiService.post(SIGNIN_API_URL, __assign({}, values))];
                case 1:
                    res = _c.sent();
                    console.log(res);
                    //signIn(values);
                    if (res.error) {
                        showMsg(res.error);
                        return [2 /*return*/];
                    }
                    else {
                        signIn(res.data.accessToken);
                        navigate('/');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var formSchema = new FormSchema()
        .setSubmit(signInHandler)
        .setLang(lang)
        .setField({
        name: 'username',
        component: 'input',
        valueFormat: "integer",
        mask: "+99999999999",
        type: 'text',
        placeholder: { ru: "email", en: "email" },
        label: { ru: 'Email', en: 'Email' },
        rules: [
            { type: 'required', prompt: { ru: 'Поле обязатeльно для заполнения', en: 'Field required' } },
        ]
    })
        .setField({
        name: 'password',
        type: 'password',
        component: 'input',
        placeholder: { ru: "*****", en: "*****" },
        label: { ru: 'Пароль (мин. 6 символов)', en: 'Password (min 6 char)' },
        rules: [
            { type: 'required', prompt: { ru: 'Поле обязатeльно для заполнения', en: 'Field required' } },
        ]
    })
        .setButtons([
        {
            label: { ru: 'Войти', en: 'Sign in' },
            class: 'prb-button blue',
            type: 'submit',
            name: 'login_button',
        }
    ]);
    formSchema.setInitialValues({
        username: 'maksimov_den@mail.ru',
        password: 'z7893727',
    });
    formSchema.getField('password').setProp('elementProps', {
        icons: [
            _jsx(Icon, { fill: "#FF0000", style: { width: '30px', padding: '11px 20px 0 0' }, handleOnMouseDown: function (event, props) {
                    props.setInputType("text");
                }, handleOnMouseUp: function (event, props) {
                    props.setInputType("password");
                }, children: _jsx(PasswordEyeIcon, {}) })
        ],
    });
    return (_jsx("div", { className: "content-container", children: _jsx("div", { className: "borders-box-v", children: _jsxs("div", { className: "borders-box-v-content", children: [_jsx(Helmet, { children: _jsx("title", { children: "\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u044F" }) }), _jsxs("div", { style: { width: '300px', margin: '200px auto', textAlign: 'center' }, children: [_jsx("div", { className: "quote-text mb40", children: "\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u0430\u0446\u0438\u044F" }), _jsx("div", { ref: telegramWrapperRef, className: "telegram-btn-wrapper" })] })] }) }) }));
};
export default LoginPage;
