import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { Cell, Row, Table } from "@app/ui/table";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { SettingsContext } from "@app/settings/settings";
import { ContentExpander } from "@app/accordion";
import { Button } from "@app/form";
var GamesListHomePage = function (props) {
    var _a = useContext(SettingsContext), lang = _a.lang, setLang = _a.setLang;
    if (!props.data) {
        return _jsx("div", { children: "\u0417\u0430\u0433\u0440\u0443\u0436\u0430\u044E" });
    }
    return (_jsxs("div", { className: "pd40", children: [_jsxs(Table, { class: "game-table", gridTemplateColumns: "120px minmax(200px, 1fr) 140px 140px 120px 120px", children: [_jsxs(Row, { class: "head", children: [_jsxs(Cell, { children: [lang == 'ru' && '№ Игры', lang == 'en' && 'Game number'] }), _jsxs(Cell, { children: [lang == 'ru' && 'Название игры', lang == 'en' && 'Game name'] }), _jsxs(Cell, { children: [lang == 'ru' && 'Бог : Дьявол', lang == 'en' && 'God : Devil'] }), _jsxs(Cell, { children: [lang == 'ru' && 'Игрок', lang == 'en' && 'Player'] }), _jsxs(Cell, { children: [lang == 'ru' && 'Начало', lang == 'en' && 'Start'] }), _jsxs(Cell, { children: [lang == 'ru' && 'Завершение', lang == 'en' && 'Finish'] })] }, "head"), props.data.map(function (record) {
                        return (_jsxs(Row, { record: record, children: [_jsx(Cell, { children: _jsxs("b", { children: [record.id, "/", moment(record.created_at).format('mm/YY')] }) }), _jsx(Cell, { style: {
                                        overflow: 'visible',
                                        cursor: 'pointer',
                                        padding: '0',
                                        maxHeight: '300px'
                                    }, children: _jsx(ContentExpander, { contentLength: 50, style: { padding: '20px' }, title: {
                                            show: lang == 'ru' ? 'Ещё...' : 'More...',
                                            hide: lang == 'ru' ? '...Скрыть' : '...Hide'
                                        }, children: record.title }) }), _jsxs(Cell, { style: {
                                        textAlign: 'center',
                                        fontWeight: '700',
                                        color: '#EBB867'
                                    }, children: [record.count_positive, " : ", record.count_negative] }), _jsx(Cell, { children: record.gamer && record.gamer.username }), _jsx(Cell, { children: moment(record.date_start).format('DD/MM/YY') }), _jsx(Cell, { children: moment(record.date_finish).format('DD/MM/YY') })] }, record.id));
                    }), props.data.length ? null :
                        _jsxs(Row, { children: [_jsxs(Cell, { children: [lang == 'ru' && 'Итого', lang == 'en' && 'Total'] }), _jsx(Cell, {}), _jsx(Cell, { children: "0" }), _jsx(Cell, { children: "0" }), _jsx(Cell, {}), _jsx(Cell, {})] })] }), _jsx("div", { className: "buttons-panel pt80", style: { alignItems: 'center' }, children: _jsx(NavLink, { className: "link-gold", to: "/games/", children: _jsx(Button, { class: "submit-btn-gold", label: lang == 'ru' ? 'Все игры' : 'All Games' }) }) })] }));
};
export default GamesListHomePage;
