import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext } from "react";
import Navbar from "./navbar";
import { AuthContext } from "@app/auth-wrapper";
import { SettingsContext } from "@app/settings/settings";
import { NavLink, useNavigate } from "react-router-dom";
var Header = function () {
    var _a = useContext(SettingsContext), lang = _a.lang, setLang = _a.setLang;
    var _b = useContext(AuthContext), isAuth = _b.isAuth, logout = _b.logout, user = _b.user;
    var navigate = useNavigate();
    var switchLang = function () {
        setLang(lang == "ru" ? 'en' : 'ru');
    };
    var logoutHandler = function () {
        var callback = function () {
            return navigate("/auth/login");
        };
        logout(callback);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "head-settings-panel", children: [_jsxs("div", { className: "setting_panel-link", children: [lang == "ru" && _jsx("a", { href: "#", onClick: switchLang, children: "English" }), lang == "en" && _jsx("a", { href: "#", onClick: switchLang, children: "\u0420y\u0441\u0441\u043A\u0438\u0439" })] }), _jsxs("div", { className: "setting_panel-link", children: [isAuth && _jsx(NavLink, { to: "/profile", children: user.username }), !isAuth && lang == "ru" && _jsx("div", { onClick: function () { return navigate('/auth/login'); }, children: "\u0412\u043E\u0439\u0442\u0438" }), !isAuth && lang == "en" && _jsx("div", { onClick: function () { return navigate('/auth/login'); }, children: "Login" })] })] }), _jsxs("div", { className: "big-logo", children: [_jsx("div", { className: "big-logo-name", children: "Besupr Game" }), _jsx("div", { className: "big-logo-slogan", children: lang == 'ru' ? 'Твоя Самая Сильная Игра' : 'Your Strongest Game' })] }), _jsx("div", { className: "layout-header", children: _jsx(Navbar, {}) })] }));
};
export default Header;
