import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect } from 'react';
import Icon, { CloseIcon } from "@app/ui/icons";
import { BalloonContext } from "@app/balloon/balloon-wrapper";
var BalloonMassage = function (props) {
    var closeMsg = useContext(BalloonContext).closeMsg;
    useEffect(function () {
        var timeoutId = setTimeout(function () {
            closeMsg(props.unique);
        }, 3000);
        // Cleanup function to clear the timeout if the component unmounts
        return function () { return clearTimeout(timeoutId); };
    }, []);
    var closeMsgHandler = function () {
        closeMsg(props.unique);
    };
    return (_jsxs("div", { className: "porabote-balloon_msg", children: [_jsx("div", { className: "porabote-balloon_msg__title", children: props.title }), _jsx("div", { className: "porabote-balloon_msg__close", onClick: closeMsgHandler, children: _jsx(Icon, { size: 15, fill: "rgba(85, 85, 98, 0.4)", fillHover: "#333", children: _jsx(CloseIcon, {}) }) })] }));
};
export default BalloonMassage;
