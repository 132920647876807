var StringFormat = function () {
    var priceFormatInt = function (rawValue, mask) {
        //var matches = test.match(/^[0-9]{1,6}(\\.\\d{1,2})?$/);
        if (mask === void 0) { mask = "*** *** *** ***"; }
        if (rawValue === 0) {
            return 0;
        }
        else if (!rawValue) {
            return "";
        }
        rawValue = rawValue.toString();
        var value = rawValue.split(".")[0].replace(/\D/g, ""); //parseInt(rawValue).toString();
        //.match(/.{1,4}/g)?.join(" ").substr(0, 19) || "";
        var newValue = "";
        var cursorValue = value.length - 1;
        for (var cursorMask = mask.length - 1; cursorMask >= 0; cursorMask--) {
            if (!value[cursorValue]) {
                break;
            }
            if (mask[cursorMask] != '*') {
                newValue += mask[cursorMask];
                if (mask[cursorMask] == value[cursorValue]) {
                    cursorValue--;
                }
            }
            else {
                newValue += value[cursorValue];
                cursorValue--;
            }
        }
        var revertValue = "";
        for (var i = newValue.length - 1; i >= 0; i--) {
            revertValue += newValue[i];
        }
        return revertValue;
    };
    return {
        priceFormatInt: priceFormatInt,
    };
};
export default StringFormat();
