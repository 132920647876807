var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useEffect, useState } from 'react';
import JwtHandler from "@/app/jwt/jwt-handler";
import LocalStorageService from "@/app/local-storage/local-storage-service";
import { AUTH_URL } from "@/configs";
import ApiService from "@/services";
var initialAuth = {
    user: null,
    isAuth: false,
    setIsAuth: Function,
    isAuthInited: false,
    signIn: Function,
    signOut: Function,
    signUp: Function,
    refreshAccessToken: Function,
};
export var AuthContext = createContext(initialAuth);
var AuthWrapper = function (props) {
    var _a = useState(false), isAuth = _a[0], setIsAuth = _a[1];
    var _b = useState(false), isAuthInited = _b[0], setIsAuthInited = _b[1];
    var _c = useState(initialAuth), user = _c[0], setUser = _c[1];
    useEffect(function () {
        setAuth();
    }, [isAuth, isAuthInited]);
    var setAuth = function () {
        var authData = JwtHandler.getTokenData();
        if (authData) {
            setUser(authData);
            setIsAuth(true);
        }
        setIsAuthInited(true);
    };
    var signUp = function (values, callback) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ApiService.post("/signUp", values, { url: AUTH_URL })];
                case 1:
                    res = _a.sent();
                    if (callback) {
                        callback(res);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var login = function (accessToken) { return __awaiter(void 0, void 0, void 0, function () {
        var userData;
        return __generator(this, function (_a) {
            LocalStorageService.setAccessToken(accessToken);
            userData = JwtHandler.parsePayload(accessToken);
            setUser(userData);
            setIsAuth(true);
            setIsAuthInited(true);
            return [2 /*return*/, userData];
        });
    }); };
    var logout = function (callback) {
        LocalStorageService.removeAccessToken();
        setIsAuth(false);
        setUser(null);
        if (typeof callback == "function") {
            callback();
        }
    };
    var refreshAccessToken = function (accountId, callback) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ApiService.post("/auth/refreshAccessToken", {
                        token: LocalStorageService.getAccessToken()
                    })];
                case 1:
                    res = _a.sent();
                    login(res.data.access_token);
                    return [2 /*return*/];
            }
        });
    }); };
    if (!isAuthInited) {
        return _jsx("div", {});
    }
    return (_jsx(AuthContext.Provider, { value: {
            user: user,
            isAuth: isAuth,
            login: login,
            logout: logout,
            refreshAccessToken: refreshAccessToken,
            signUp: signUp,
            isAuthInited: isAuthInited
        }, children: props.children }));
};
export default AuthWrapper;
