import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState } from 'react';
var initialValues = {
    lang: localStorage.getItem('lang') || 'ru',
    setLang: function () { console.log(99); },
};
export var SettingsContext = createContext(initialValues);
var Settings = function (props) {
    var _a = useState(localStorage.getItem('lang') || 'ru'), lang = _a[0], setLang = _a[1];
    var setLangContext = function (value) {
        setLang(value);
        localStorage.setItem('lang', value);
    };
    return (_jsx(SettingsContext.Provider, { value: {
            lang: lang,
            setLang: setLangContext,
        }, children: props.children }));
};
export default Settings;
