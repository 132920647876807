import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useEffect, useState } from 'react';
import ObjectMapper from "../collections/ObjectMapper";
import Validation from "./validation";
var initContextValues = {
    setValue: function (name, number) { },
    getValue: function (name) { },
    values: {},
    schema: {},
    onSubmit: function () { },
    setValidationError: function () { },
    validationErrors: {},
};
export var FormContext = createContext(initContextValues);
var Form = function (_a) {
    var initValues = _a.initValues, children = _a.children, onSubmit = _a.onSubmit, schema = _a.schema, _b = _a.method, method = _b === void 0 ? "POST" : _b;
    if (!initValues) {
        initValues = schema.getInitialValues();
    }
    var _c = useState(initValues), values = _c[0], setValues = _c[1];
    var _d = useState({}), validationErrors = _d[0], setValidationError = _d[1];
    //const [formKey, setFormKey] = useState(0);
    if (!onSubmit) {
        onSubmit = schema.submit;
    }
    useEffect(function () {
    }, []);
    var onSubmitHandler = function (context) {
        var errors = Validation(context).validate();
        if (Object.keys(errors).length) {
            return;
        }
        if (onSubmit) {
            onSubmit(context);
        }
    };
    var setValidationErrorHandler = function (errors) {
        setValidationError(errors);
    };
    var setValue = function (name, value, mode) {
        if (mode === void 0) { mode = 'merge'; }
        var newValues = ObjectMapper.setValue(name, value, values, mode);
        setValues(newValues);
    };
    var getValue = function (name) {
        return ObjectMapper.get(name, values);
    };
    var initFormContextValues = {
        values: values,
        getValue: getValue,
        setValue: setValue,
        onSubmit: onSubmitHandler,
        schema: schema,
        validationErrors: validationErrors,
        setValidationError: setValidationErrorHandler,
    };
    return (_jsx(FormContext.Provider, { value: initFormContextValues, children: _jsx(_Fragment, { children: children }) }));
};
export default Form;
