import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from 'react';
import './assets/balloon.less';
import BalloonMassage from "@app/balloon/balloon-massage";
import { BalloonContext } from "@app/balloon/balloon-wrapper";
var Balloon = function (props) {
    var msgs = useContext(BalloonContext).msgs;
    return (_jsx("div", { className: "porabote-balloon", children: msgs.map(function (msg, index) {
            return _jsx(BalloonMassage, { title: msg.title, unique: msg.unique, type: msg.type }, msg.unique);
        }) }));
};
export default Balloon;
