var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from "react";
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { FormContext } from "@/app/form";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
var InputDate = function (props) {
    var context = useContext(FormContext);
    var _a = useState(null), startDate = _a[0], setStartDate = _a[1];
    var setStartHandler = function (value) {
        setStartDate(value);
    };
    var onChangeHandler = function (date) {
        if (context) {
            context.setValue(props.name || "", moment(date).format('YYYY-MM-DD HH:mm:ss'));
        }
        setStartDate(date);
        if (typeof props.onSelect == "function") {
            props.onSelect(__assign(__assign({ context: context }, props), { newValue: date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null }));
        }
    };
    useEffect(function () {
        var value = props.value;
        if (!value) {
            value = context.getValue(props.name);
        }
        if (typeof props.value == "string" && props.value.length > 0) {
            value = new Date(props.value);
        }
        setStartDate(value);
    }, []);
    var convertDate = function (inputFormat) {
        function pad(s) {
            return (s < 10) ? '0' + s : s;
        }
        var d = new Date(inputFormat);
        return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
    };
    return (_jsx("div", { children: _jsxs("div", { className: "form-item ".concat(props.classModifier ? props.classModifier : ''), children: [_jsx("label", { className: "form-item__label", children: props.label }), _jsx(DatePicker, { format: "dd.MM.yy", selected: setStartHandler, value: startDate, allowClear: props.allowClear ? props.allowClear : false, disableCalendar: props.disableCalendar || false, onChange: onChangeHandler, isClearable: true })] }) }));
};
export default InputDate;
