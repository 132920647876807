var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var Validation = function (formContext) {
    var schema = formContext.schema, values = formContext.values, setValidationError = formContext.setValidationError, validationErrors = formContext.validationErrors;
    var validate = function () {
        var errors = __assign({}, validationErrors);
        if (!schema) {
            return [];
        }
        var _loop_1 = function (fieldName) {
            var lang = schema.fields[fieldName].props.lang;
            var rules = schema.fields[fieldName].props.rules;
            if (!rules) {
                return "continue";
            }
            rules.forEach(function (rule) {
                var value = values[fieldName];
                var res = checkRule(rule, value);
                if (!res) {
                    if (!errors[fieldName]) {
                        errors[fieldName] = {};
                    }
                    errors[fieldName][rule.type] = rule.prompt[lang];
                }
                else {
                    if (errors[fieldName]) {
                        delete errors[fieldName][rule.type];
                    }
                }
            });
        };
        for (var fieldName in schema.fields) {
            _loop_1(fieldName);
        }
        for (var fieldName in errors) {
            if (!Object.keys(errors[fieldName]).length) {
                delete errors[fieldName];
            }
        }
        setValidationError(errors);
        return errors;
    };
    var checkRule = function (rule, value) {
        if (rule.type == 'required') {
            return _isRequired(value);
        }
    };
    var _isRequired = function (value) {
        if (!value || (typeof value == "string" && !value.length)) {
            return false;
        }
        return true;
    };
    return {
        validate: validate
    };
};
export default Validation;
