var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router";
import ApiService from "@/services/api-service";
import { SettingsContext } from "@app/settings/settings";
import { BalloonContext } from "@app/balloon/balloon-wrapper";
import { ModalContext } from "@app/modal/modal-wrapper";
import PlayPageHistory from "@/components/games/pages/play-page/templates/play-page-history";
import PlayPagePanel from "@/components/games/pages/play-page/templates/play-page-panel";
import PlayGameDetails from "@/components/games/pages/play-page/templates/play-game-details";
import { Button } from "@app/form";
import { useNavigate } from "react-router-dom";
var PlayPage = function () {
    var id = useParams().id;
    var _a = useState(null), game = _a[0], setGame = _a[1];
    var lang = useContext(SettingsContext).lang;
    var showMsg = useContext(BalloonContext).showMsg;
    var navigate = useNavigate();
    var _b = useContext(ModalContext), closeDialog = _b.closeDialog, openDialog = _b.openDialog;
    var cancelGameHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            openDialog(_jsx("div", { children: lang == 'ru' ? "Вы уверены что хотите удалить игру?" : 'Are you sure you want to delete the game?' }), [
                _jsx("div", { onClick: closeDialog, className: "porabote-modal-dialog-button", children: lang == 'ru' ? 'Отмена' : 'Cancel' }),
                _jsx("div", { onClick: cancelGameRequest, className: "porabote-modal-dialog-button", children: lang == 'ru' ? 'Удалить' : 'Delete' })
            ]);
            return [2 /*return*/];
        });
    }); };
    var cancelGameRequest = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ApiService.get('/games/action/deletGame', {}, {
                        query: {
                            id: game.id,
                        }
                    })];
                case 1:
                    res = _a.sent();
                    closeDialog();
                    if (typeof res.error != "undefined") {
                        openDialog(_jsx("div", { children: res.error }), [
                            _jsx("div", { onClick: closeDialog, className: "porabote-modal-dialog-button", children: lang == 'ru' ? 'Понятно!' : 'Got it!' }),
                        ]);
                        return [2 /*return*/];
                    }
                    navigate("/games/my/");
                    return [2 /*return*/];
            }
        });
    }); };
    var finishGameHandler = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            openDialog(_jsx("div", { children: lang == 'ru' ? "Вы уверены что хотите отменить игру?" : 'Are you sure you want to cancel the game?' }), [
                _jsx("div", { onClick: closeDialog, className: "porabote-modal-dialog-button", children: lang == 'ru' ? 'Отмена' : 'Cancel' }),
                _jsx("div", { onClick: finishGameRequest, className: "porabote-modal-dialog-button", children: lang == 'ru' ? 'Завершить досрочно' : 'Finish early' })
            ]);
            return [2 /*return*/];
        });
    }); };
    var finishGameRequest = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ApiService.get('/games/action/finishGameEarly', {}, {
                        query: {
                            id: game.id,
                        }
                    })];
                case 1:
                    res = _a.sent();
                    closeDialog();
                    if (typeof res.error != "undefined") {
                        openDialog(_jsx("div", { children: res.error }), [
                            _jsx("div", { onClick: closeDialog, className: "porabote-modal-dialog-button", children: lang == 'ru' ? 'Понятно!' : 'Got it!' }),
                        ]);
                        return [2 /*return*/];
                    }
                    getRecord();
                    openDialog(_jsx("div", { children: lang == 'ru' ? 'Игра завершена! Выиграл Дьявол!' : 'Game over! The Devil wins!' }), [
                        _jsx("div", { onClick: closeDialog, className: "porabote-modal-dialog-button", children: lang == 'ru' ? 'Понятно! Попробую ещё!' : 'It\'s clear! I\'ll try again!' }),
                    ]);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getRecord();
    }, [id]);
    var getRecord = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ApiService.get("/games/get/".concat(id), {}, {
                        query: {
                            relations: ['history', 'vote_today', 'files']
                        }
                    })];
                case 1:
                    res = _a.sent();
                    setGame(res.data);
                    return [2 /*return*/];
            }
        });
    }); };
    var vote = function (value) {
        var res = ApiService.post("/games/action/vote/", {
            id: game.id,
            value: value,
        }, {
            onSuccess: function (resp) {
                getRecord();
                openDialog(_jsx("div", { children: lang == 'ru' ? 'Голос принят' : 'Vote accepted' }), [
                    _jsxs("div", { onClick: closeDialog, className: "porabote-modal-dialog-button", children: [value === 1 &&
                                _jsx(_Fragment, { children: lang == 'ru' ? 'Отлично' : 'Super' }), value === 0 &&
                                _jsx(_Fragment, { children: lang == 'ru' ? 'Буду пробовать дальше!' : 'I will try!' })] })
                ]);
            },
            onError: function (error) {
                showMsg(error);
            }
        });
    };
    if (!game) {
        return _jsx("div", { children: "Loading" });
    }
    return (_jsx("div", { className: "content-container", children: _jsx("div", { className: "borders-box-v", children: _jsxs("div", { className: "borders-box-v-content", children: [_jsx(PlayPagePanel, { game: game, vote: vote }), _jsx(PlayGameDetails, { data: game }), game.status_id == 6 &&
                        _jsx("div", { children: _jsx("div", { className: "games-page-add-billet", onClick: finishGameHandler, children: _jsx("div", { className: "games-page-add-billet__btn", children: _jsx("div", { className: "games-page-add-billet__btn-text", children: lang == 'ru' ? 'ЗАВЕРШИТЬ ДОСРОЧНО' : 'FINISH EARLY' }) }) }) }), _jsxs("div", { className: "pd40", children: [_jsx("div", { style: { paddingTop: '50px' }, className: "game-page-title", children: lang == 'ru' ? 'История' : 'History' }), _jsx(PlayPageHistory, { files: game.files, game: game, data: game.history })] }), _jsx("div", { className: "buttons-panel pt80", style: { justifyContent: 'center' }, children: _jsx(Button, { onClick: cancelGameHandler, class: "submit-btn-gold min", label: lang == 'ru' ? 'Удалить игру' : 'Delete game' }) })] }) }) }));
};
export default PlayPage;
