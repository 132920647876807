import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { Cell, Row, Table } from "@app/ui/table";
import moment from "moment";
import { SettingsContext } from "@app/settings/settings";
var PlayGameDetails = function (props) {
    var lang = useContext(SettingsContext).lang;
    var data = props.data;
    return (_jsxs("div", { className: "pd40", children: [_jsx("div", { className: "game-page-title", children: lang == 'ru' ? 'Данные игры' : 'Game details' }), _jsxs(Table, { class: "game-table", gridTemplateColumns: "140px 1fr", children: [_jsxs(Row, { class: "head", children: [_jsx(Cell, { children: lang == 'ru' ? 'Описание' : 'Description' }), _jsx(Cell, { style: { whiteSpace: 'normal' }, children: _jsx("span", { style: { fontSize: '1.2em' }, children: data.title }) })] }), _jsxs(Row, { class: "head", children: [_jsx(Cell, { children: lang == 'ru' ? 'Период игры' : 'Game period' }), _jsx(Cell, { children: _jsxs("div", { children: [_jsxs("span", { style: { fontSize: '1.2em' }, children: [moment(data.date_start).format('DD-MM-YY HH:MM'), " "] }), "/ ", _jsx("span", { style: { fontSize: '1.2em' }, children: moment(data.date_finish).format('DD-MM-YY HH:MM') })] }) })] }), _jsxs(Row, { class: "head", children: [_jsx(Cell, { children: lang == 'ru' ? 'Счёт' : 'Score' }), _jsx(Cell, { children: _jsxs("div", { children: [_jsxs("span", { style: { fontSize: '1.2em' }, children: ["\u0411\u043E\u0433 ", data.count_positive, " "] }), " : ", _jsxs("span", { style: { fontSize: '1.2em' }, children: [data.count_negative, " \u0414\u044C\u044F\u0432\u043E\u043B"] })] }) })] }), _jsxs(Row, { class: "head", children: [_jsx(Cell, { children: lang == 'ru' ? 'Час напоминания' : 'Remind hour' }), _jsx(Cell, { children: _jsx("div", { children: _jsxs("span", { style: { fontSize: '1.2em' }, children: [data.remind_hour, ":00 "] }) }) })] })] })] }));
};
export default PlayGameDetails;
