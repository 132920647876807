import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { SettingsContext } from "@app/settings/settings";
import PlayPanelTimer from "@/components/games/pages/play-page/templates/play-panel-timer";
import PlayPanelGameOver from "@/components/games/pages/play-page/templates/play-panel-game-over";
var PlayPagePanel = function (props) {
    var lang = useContext(SettingsContext).lang;
    var game = props.game, vote = props.vote;
    if (game.is_win !== null) {
        return _jsx(PlayPanelGameOver, { game: game });
    }
    if (game.status_id == 6 && game.vote_today) {
        return _jsx(PlayPanelTimer, { game: game });
    }
    return (_jsxs("div", { children: [_jsxs("div", { className: "slogan-white", children: [lang == 'ru' && 'Отметь сделал или не сделал и сохрани результат', lang != 'ru' && 'Mark whether you did or did not do it and save the result.'] }), game.status_id == 6 &&
                _jsxs(_Fragment, { children: [_jsxs("div", { className: "slogan-gold", children: [lang == 'ru' && 'Каждый день нужно будет отмечать - Сделал / Не Сделал', lang != 'ru' && 'Every day you will need to mark - Did / Didn\'t do'] }), _jsxs("div", { className: "slogan-white", style: { padding: '20px' }, children: [lang == 'ru' && 'Сделал?', lang != 'ru' && 'Did?'] })] }), game.status_id == 6 &&
                _jsxs("div", { className: "game-play-select-panel", children: [_jsxs("div", { className: "game-play-select-panel-item god", onClick: function () { return vote(1); }, children: [_jsx("div", { className: "game-play-select-panel-img god" }), _jsx("div", { className: "game-play-select-panel-button god", children: _jsx("div", { className: "game-play-select-panel-button-title", children: lang == 'ru' ? 'Сделал' : 'Did' }) }), _jsx("div", { className: "game-play-select-panel-title god", children: lang == 'ru' ? 'Выиграл Бог' : 'God won' })] }), _jsxs("div", { className: "game-play-select-panel-item devil", onClick: function () { return vote(0); }, children: [_jsx("div", { className: "game-play-select-panel-img devil" }), _jsx("div", { className: "game-play-select-panel-button devil", children: _jsx("div", { className: "game-play-select-panel-button-title", children: lang == 'ru' ? 'Не сделал' : 'Didn\'t' }) }), _jsx("div", { className: "game-play-select-panel-title devil", children: lang == 'ru' ? 'Выиграл Дьявол' : 'Devil won' })] })] })] }));
};
export default PlayPagePanel;
