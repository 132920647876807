var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef, useContext } from "react";
import ObjectMapper from "@/app/collections/ObjectMapper";
import Option from "./Option";
import SelectTags from "./SelectTags";
import { FormContext } from "@/app/form";
var Select = function (props) {
    var context = useContext(FormContext);
    var initValue = function () {
        var value = props.value || "";
        var title = props.emptyTitle || "Не выбрано";
        if (!value.length && !isEmpty) {
            if (!props.data) {
                return { value: value, title: title };
            }
            value = props.data[0]['id'];
            title = value = props.data[0]['name'];
        }
        if (props.isMultiple && Array.isArray(props.value)) {
            value = new Set(props.value);
        }
        if (context) {
            if (context.values[props.name]) {
                value = context.values[props.name];
            }
            context.setValue(props.name, value);
        }
        return {
            value: value || "",
            title: title,
        };
    };
    var optionTitleRender = function (item) {
        if (props.optionTitle) {
            return props.optionTitle;
        }
        return function (item) { return item.name; };
    };
    var _a = useState(false), isStorageLoaded = _a[0], setIsStorageLoaded = _a[1];
    var _b = useState([]), storage = _b[0], setStorage = _b[1];
    var _c = useState({}), storageMap = _c[0], setStorageMap = _c[1];
    var isEmpty = useState(props.isEmpty || true)[0];
    var _d = useState([]), options = _d[0], setOptions = _d[1];
    var _e = useState(null), value = _e[0], setValue = _e[1];
    var name = useState(props.name || "")[0];
    var optionValueKey = useState(props.optionValueKey || "id")[0];
    var _f = useState(optionTitleRender), optionTitle = _f[0], setOptionTitle = _f[1];
    var _g = useState(props.label || ""), label = _g[0], setLabel = _g[1];
    var _h = useState(props.isDisabled || false), isDisabled = _h[0], setIsDIsabled = _h[1];
    var _j = useState(null), emptyTitle = _j[0], setEmptyTitle = _j[1];
    var _k = useState(""), seekValue = _k[0], setSeekValue = _k[1];
    var _l = useState(""), searchPhrase = _l[0], setSearchPhrase = _l[1];
    var _m = useState(300), seekDelay = _m[0], setSeekDelay = _m[1];
    var _o = useState(props.isMultiple || false), isMultiple = _o[0], setIsMultiple = _o[1];
    var _p = useState(""), inputValue = _p[0], setInputValue = _p[1];
    var _q = useState(false), isOpened = _q[0], setIsOpened = _q[1];
    var buttons = useState(props.buttons || [])[0];
    var onSelectCallback = useState(function () { return props.onSelect || null; })[0];
    var _r = useState(false), isInputFocus = _r[0], setIsInputFocus = _r[1];
    var inputElement = useState(props.inputElement || 'input')[0];
    var wrap = useRef();
    var toggle = useRef();
    var textInput = useRef();
    var dropPanel = useRef();
    useEffect(function () {
        refreshSelect();
    }, [isStorageLoaded]);
    useEffect(function () {
        setIsStorageLoaded(false);
        refreshSelect();
    }, [props.data, props.emptyTitle]);
    var refreshSelect = function () {
        var _a = initValue(), value = _a.value, title = _a.title;
        setValue(value);
        setEmptyTitle(title);
        setData();
        setDropPanelWidth();
        setElementPositions();
        setOptionsList();
    };
    var setData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, storageMap;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isStorageLoaded)
                        return [2 /*return*/];
                    data = [];
                    if (!(typeof props.setData == "function")) return [3 /*break*/, 2];
                    return [4 /*yield*/, props.setData()];
                case 1:
                    data = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    if (typeof props.data != "undefined") {
                        data = props.data;
                    }
                    _a.label = 3;
                case 3:
                    setStorage(__spreadArray([], data, true));
                    storageMap = new Map();
                    data.map(function (item, index) { return storageMap.set(ObjectMapper.get(optionValueKey, item), index); });
                    setStorageMap(storageMap);
                    setIsStorageLoaded(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var clearStorage = function () {
        setStorage([]);
        setStorageMap([]);
    };
    var setOptionsList = function () { return __awaiter(void 0, void 0, void 0, function () {
        var selectedOptionTitle, optionsList, emptyOption;
        return __generator(this, function (_a) {
            selectedOptionTitle = null;
            optionsList = storage.map(function (item, index) {
                var optionValue = ObjectMapper.get(optionValueKey, item);
                var itemTitle = optionTitle(item);
                var isSelected = (value == optionValue) ? true : false;
                if (isSelected)
                    selectedOptionTitle = itemTitle;
                return (_jsx(Option, { value: optionValue, selected: isSelected, onSelect: handleOnSelect, onSelectMultiple: handleOnSelectMultiple, isMultiple: isMultiple, children: itemTitle }, optionValue));
            });
            if (selectedOptionTitle && !isMultiple) {
                setInputValue(selectedOptionTitle);
            }
            if (isEmpty) {
                emptyOption = setEmptyOption();
                setOptions(__spreadArray([emptyOption], optionsList, true));
                return [2 /*return*/];
            }
            setOptions(optionsList);
            return [2 /*return*/];
        });
    }); };
    var setEmptyOption = function () {
        return (_jsx(Option, { value: "", selected: !value ? true : false, onSelect: handleOnSelect, onSelectMultiple: handleOnSelectMultiple, isMultiple: isMultiple, storage: storage, storageMap: storageMap, children: emptyTitle || "" }, "emptyKey"));
    };
    var handleOnSelect = function (e, params) {
        var newValue = params.newValue, title = params.title;
        setValue(newValue);
        setInputValue(title.toString());
        setIsOpened(false);
        setSeekValue("");
        if (context) {
            context.setValue(name, newValue);
        }
        if (typeof onSelectCallback === "function") {
            onSelectCallback(e, __assign(__assign({}, params), { storage: storage, storageMap: storageMap, newValue: newValue, context: context }));
        }
    };
    var handleOnSelectMultiple = function (e, params) {
        var newValue = params.newValue, title = params.title;
        if (!newValue)
            return;
        if (value instanceof Set) {
            value.add(newValue);
        }
        setValue(value);
        setIsOpened(false);
        if (context) {
            context.setValue(name, value); //Array.from(value)
        }
    };
    var checkIsDIsabled = function () {
        // let disabled = (props.disabled) ? true : false;
        // if (typeof props.disabled == "function" ) {
        //   disabled = props.disabled(props.context);
        // }
        return false;
    };
    var setElementPositions = function () {
        dropPanel.current.style.top = "56px";
    };
    var toggleDropList = function () {
        if (!isOpened) {
            textInput.current.focus();
            setIsOpened(true);
        }
        else {
            setIsOpened(false);
        }
    };
    var showDropPanel = function () {
        dropPanel.current.style.zIndex = String(1000);
        setIsOpened(true);
    };
    var handleOnFocus = function () {
        showDropPanel();
        setIsInputFocus(true);
    };
    var handleOnBlur = function (e) {
        hideDropPanel();
        if (!inputValue.length && value) {
            handleOnSelect(e, {
                newValue: value,
                title: '', //storage[storageMap[value]],
            });
            setIsInputFocus(false);
        }
    };
    var hideDropPanel = function () {
        dropPanel.current.style.zIndex = String(10);
        setIsOpened(false);
    };
    /* Если селект был инициализирован в display:none, обновляем ширину */
    var setDropPanelWidth = function () {
        if (wrap.current && dropPanel.current && wrap.current.offsetWidth !== dropPanel.current.offsetWidth) {
            dropPanel.current.style.width = wrap.current.offsetWidth + "px";
        }
    };
    var buildOptions = function () {
        return options.map(function (option, index) {
            //  if (typeof option === "undefined" || typeof option.props === "undefined") return
            if (!checkOnSeek(option.props.children || ""))
                return;
            // let onSelect = clickByOption;
            // if (mode === "tags") afterSelectCallback = clickByOptionTagsMode
            return option;
            // return React.cloneElement(option, {...option.props, onSelect})
        });
    };
    var checkOnSeek = function (value) {
        if (typeof value != "string" || !value)
            return true;
        return (value
            && value.length > 0
            && !value.toLowerCase().includes(seekValue)) ? false : true;
    };
    var onClickByInput = function () {
        textInput.current.select();
    };
    var onChangeInput = function (e) {
        setInputValue(e.target.value);
        setSeekValue(e.target.value.toLowerCase());
    };
    var dropStyle = { visibility: "hidden" };
    if (isOpened) {
        dropStyle = { visibility: "visible" };
    }
    var reOptions = buildOptions();
    var errors = context.validationErrors[props.name];
    return (_jsxs("div", { className: "form-item ".concat(props.classModifier ? props.classModifier : ''), children: [props.label &&
                _jsx("label", { className: "form-item__label", children: props.label }), _jsx("div", { className: "form-item__select-wrap", ref: wrap, children: _jsxs("span", { className: "form-item__select-custom", children: [inputElement == 'input' &&
                            _jsx("input", { disabled: isDisabled, ref: textInput, className: "form-item__select-custom__input", type: "text", onChange: onChangeInput, onClick: onClickByInput, onFocus: handleOnFocus, onBlur: handleOnBlur, value: ((inputValue) ? inputValue : (isInputFocus) ? inputValue : emptyTitle) || "" }), inputElement == 'div' &&
                            _jsx("input", { readOnly: true, style: { cursor: "pointer" }, ref: textInput, className: "form-item__select-custom__input", onChange: onChangeInput, onMouseDown: function (e) {
                                    if (isDisabled)
                                        return;
                                    e.preventDefault();
                                    toggleDropList();
                                }, value: ((inputValue) ? inputValue : (isInputFocus) ? inputValue : emptyTitle) || "" }), _jsx("span", { ref: toggle, className: "form-item__select-custom__toggle", onMouseDown: function (e) {
                                if (isDisabled)
                                    return;
                                e.preventDefault();
                                toggleDropList();
                            }, children: _jsx("span", { className: "form-item__select-custom__icon" }) }), _jsx("div", { style: { visibility: (isOpened) ? "visible" : "hidden" }, ref: dropPanel, className: "form-item__select__drop-blok", children: _jsx("span", { children: reOptions }) }), _jsx("div", { className: "form-item__select__buttons" })] }) }), isMultiple && value instanceof Set &&
                _jsx(SelectTags, { name: "tags__".concat(props.name), context: context, setTagTitle: props.setTagTitle, storage: storage, storageMap: storageMap, value: value }, "tags__".concat(props.name)), errors &&
                _jsx("div", { className: "form_item__input_error", children: Object.keys(errors).map(function (errorType) {
                        return _jsx("span", { children: errors[errorType] }, errorType);
                    }) })] }));
};
export default Select;
