var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useRef, useState } from "react";
import { FilesContext } from "@app/files/files";
import { ModalContext } from "@app/modal/modal-wrapper";
import AvatarUploaderPreviewDialog from "@app/files/avatar-uploader/avatar-uploader-preview-dialog";
var AvatarUploader = function (props) {
    var inputRef = useRef(null);
    var _a = useContext(FilesContext), upload = _a.upload, fileInfo = _a.fileInfo, uploadUrl = _a.uploadUrl;
    var _b = useState(null), file = _b[0], setFile = _b[1];
    var openDialog = useContext(ModalContext).openDialog;
    // const approveAvatar = () => {
    //   console.log(file);
    // }
    var onSelectHandler = function (e) {
        var file = e.target.files[0];
        openDialog(_jsx(AvatarUploaderPreviewDialog, { selectedFile: file, uploadOptions: __assign(__assign({}, fileInfo), { uploadUrl: uploadUrl }), uploadCallback: props.uploadCallback }));
        inputRef.current.value = null;
    };
    return (_jsxs("label", { htmlFor: "upload", className: props.className ? props.className : 'upload__label-default', children: [_jsx("input", { ref: inputRef, name: props.name, className: "upload__upload-input-default", type: "file", id: "upload", onChange: onSelectHandler }), props.children && props.children, props.children ? '' : 'Загрузить файл'] }));
};
export default AvatarUploader;
